import { all, fork } from "redux-saga/effects";
import authSagas from "./auth/saga";
import socketsSaga from "./eventBus/saga";
import dashBoardSagas from "./dashboard/saga";
import deliveriesSagas from "./deliveries/saga";
import invoicesSagas from "./invoices/saga";
import userSaga from "./users/saga";
import orderItems from "./orderItems/saga";
import priceListsSagas from "./priceLists/saga";
import pricesSagas from "./prices/saga";
import errorReportingSaga from "./errorReporting/saga";

export default function* rootSaga() {
  yield all([
    fork(authSagas),
    fork(socketsSaga),
    fork(dashBoardSagas),
    fork(deliveriesSagas),
    fork(invoicesSagas),
    fork(userSaga),
    fork(orderItems),
    fork(priceListsSagas),
    fork(pricesSagas),
    fork(errorReportingSaga),
  ]);
}
