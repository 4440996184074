import { BaseService } from "../BaseService";
import { API_ROUTES } from "../../constants/routes";

class OrderLinesService extends BaseService {
  constructor(url) {
    super(url);
    this.getOrderItems = this.getOrderItems.bind(this);
    this.getItem = this.getItem.bind(this);
    this.saveItem = this.saveItem.bind(this);
    this.updateItem = this.updateItem.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
  }

  async getOrderItems(params) {
    try {
      const response = await this.agent.get("", {
        params: {
          ...params,
          sort_by: "id",
          order: "desc",
          include:
            "dental_lab_delivery,dental_lab_order.clinic,dental_lab_order.patient,dental_lab_supplier_invoice_row",
        },
      });

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      throw error;
    }
  }

  async getItem(id) {
    try {
      const response = await this.agent.get("", {
        params: {
          dental_lab_order_id: id,
          include: "vat_code",
          exclude_with_delivery: 1,
        },
      });
      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      throw error;
    }
  }

  async saveItem(data, id) {
    try {
      const response = await this.agent.post(
        "",
        { data },
        {
          params: {
            dental_lab_order_id: id,
          },
        }
      );

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      throw error;
    }
  }

  async updateItem(itemId, data, orderId) {
    try {
      const response = await this.agent.patch(
        `${itemId}`,
        { data },
        {
          params: {
            dental_lab_order_id: orderId,
          },
        }
      );

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      return null;
    }
  }

  async deleteItem(itemId) {
    try {
      await this.agent.delete(`/${itemId}`);

      return true;
    } catch (error) {
      this.processError(error);
      return null;
    }
  }
}

const ServiceInstance = new OrderLinesService(
  API_ROUTES.labDentalLabOrderLines
);

export default ServiceInstance;
export { ServiceInstance as OrderLinesService };
