import React from "react";

const FilterIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlSpace='preserve'
    style={{ width: "12px", height: "12px" }}
    viewBox='0 0 485.008 485.008'
    {...props}
  >
    <path d='M171.501 464.698v-237.9l-166.3-192.6c-8.9-10.9-7.9-33.3 15.1-33.3h443.6c21.6 0 26.6 19.8 15.1 33.3l-162.3 187.5v147.2c0 6-2 11.1-7.1 15.1l-103.8 95.8c-12 8.9-34.3 4.1-34.3-15.1zm-106.8-423.4 142.2 164.3c3 4 5 8.1 5 13.1v200.6l64.5-58.5v-146.1c0-5 2-9.1 5-13.1l138.1-160.3h-354.8z' />
  </svg>
);
export default FilterIcon;
