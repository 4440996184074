import { dashboardActions as actions } from "./actions";

const initialState = {
  currentOrder: {},
  files: [],
  loading: false,
  orders: [],
  ordersUI: {
    perPage: 10,
    page: 1,
    orderStatus: [],
    searchValue: "",
    currentDate: new Date(),
    view: "table",
    sortValue: "created_at",
  },
  fileLoadingIds: [],
  items: [],
  checkedItemsId: [],
  deliveries: [],
  currentItem: {},
  invoices: [],
  currentInvoice: {},
  ordersPagination: {},
  invoicesPagination: {},
  searchPrices: [],
  priceListId: null,
  isSigningModalShown: false,
  isCreateInvoiceModalShown: false,
  isCreatingOrderResponse: false,
  isClosingOrder: false,
};

export const dashboardReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case actions.DASHBOARD_GET_ORDERS:
    case actions.DASHBOARD_GET_ORDER_BY_ID:
    case actions.DASHBOARD_ITEM_SAVE:
    case actions.DASHBOARD_CREATE_DELIVERY:
    case actions.DASHBOARD_ITEM_DELETE:
    case actions.DASHBOARD_ITEMS_RELOAD:
      return {
        ...state,
        loading: true,
      };
    case actions.DASHBOARD_GET_ORDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        orders: action.payload.results,
        ordersPagination: action.payload.pagination,
      };
    case actions.DASHBOARD_LOADING_ERROR:
    case actions.DASHBOARD_LOADING_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actions.DASHBOARD_ORDERS_UI_REFRESH:
      return {
        ...state,
        ordersUI: { ...state.ordersUI, ...action.payload },
      };
    case actions.DASHBOARD_FILE_LOADING_IDS_REFRESH:
      return {
        ...state,
        fileLoadingIds: action.payload,
      };
    case actions.DASHBOARD_GET_ORDER_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        currentOrder: {
          ...action.payload,
          dental_lab_order_responses: JSON.parse(
            JSON.stringify(
              action.payload?.dental_lab_order_responses
                ?.reverse()
                ?.filter((item) => item?.attributes?.signed_at)
            )
          ),
        },
        files: action.payload.files,
      };
    case actions.DASHBOARD_RESET_CURRENT_ORDERS:
      return {
        ...state,
        files: [],
        currentOrder: {},
      };

    case actions.DASHBOARD_ADD_AND_SIGN_ORDER_BY_ID:
      return {
        ...state,
        isCreatingOrderResponse: true,
      };
    case actions.DASHBOARD_ADD_AND_SIGN_ORDER_BY_ID_ERROR:
      return {
        ...state,
        isCreatingOrderResponse: false,
      };
    case actions.DASHBOARD_ADD_AND_SIGN_ORDER_BY_ID_SUCCESS:
      return {
        ...state,
        isSigningModalShown: false,
        isCreatingOrderResponse: false,
      };
    case actions.DASHBOARD_CLOSE_ORDER_BY_ID:
      return {
        ...state,
        isClosingOrder: true,
      };
    case actions.DASHBOARD_CLOSE_ORDER_BY_ID_SUCCESS:
      return {
        ...state,
        isClosingOrder: false,
      };
    case actions.DASHBOARD_CLOSE_ORDER_BY_ID_ERROR:
      return {
        ...state,
        isClosingOrder: false,
        isSigningModalShown: false,
      };
    case actions.DASHBOARD_SHOW_SIGNING_MODAL:
      return {
        ...state,
        isSigningModalShown: true,
      };
    case actions.DASHBOARD_HIDE_SIGNING_MODAL:
      return {
        ...state,
        isSigningModalShown: false,
      };
    case actions.DASHBOARD_ADD_ITEM_MODAL_SHOW:
      return {
        ...state,
        isAddItemModalShown: true,
        searchPrices: initialState.searchPrices,
      };
    case actions.DASHBOARD_ITEMS_REFRESH:
      return {
        ...state,
        items: action.payload,
      };
    case actions.DASHBOARD_CHECKED_ITEMS_ID_REFRESH:
      return {
        ...state,
        checkedItemsId: action.payload,
      };
    case actions.DASHBOARD_DELIVERIES_REFRESH:
      return {
        ...state,
        deliveries: action.payload,
      };
    case actions.DASHBOARD_CURRENT_ITEM_REFRESH:
      return {
        ...state,
        currentItem: action.payload,
      };
    case actions.DASHBOARD_ADD_ITEM_MODAL_HIDE:
      return {
        ...state,
        isAddItemModalShown: false,
        searchPrices: initialState.searchPrices,
        currentItem: initialState.currentItem,
      };
    case actions.DASHBOARD_GET_SEARCH_PRICES_SUCCESS: {
      return {
        ...state,
        searchPrices: action.payload,
      };
    }
    case actions.DASHBOARD_GET_SEARCH_PRICES_ERROR: {
      return {
        ...state,
        searchPrices: initialState.searchPrices,
      };
    }
    case actions.DASHBOARD_PRICE_LIST_ID_REFRESH: {
      return {
        ...state,
        priceListId: action.payload,
      };
    }
    default:
      return { ...state };
  }
};
