import React, { memo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { push } from "connected-react-router";

import {
  UncontrolledDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";

import MuntraLogo from "../../assets/images/muntra-logo.png";

import { clickOnMobileMenu } from "../../redux/menu/actions";
import { authActions } from "../../redux/auth/actions";

import { MobileMenuIcon } from "../icons";
import UserIcon from "../icons/UserIcon";

const Header = ({ history }) => {
  const dispatch = useDispatch();

  const { containerClassnames } = useSelector((state) => state.menu);
  const { lab, user } = useSelector((state) => state.authUser);

  const userFullName = `${user?.attributes?.first_name} ${user?.attributes?.last_name}`;

  const handleLogout = useCallback(() => {
    dispatch(authActions.logoutUser(history));
  }, [dispatch, history]);

  const onRouteChange = useCallback(
    (route) => {
      dispatch(push(route));
    },
    [dispatch]
  );

  const mobileMenuButtonClick = (e, containerClassnames) => {
    e.preventDefault();
    dispatch(clickOnMobileMenu(containerClassnames));
  };

  return (
    <nav className='navbar fixed-top'>
      <div className='d-flex align-items-center navbar-left'>
        <NavLink
          to='#'
          className='menu-button-mobile d-xs-block d-sm-block d-md-none'
          onClick={(e) => mobileMenuButtonClick(e, containerClassnames)}
        >
          <MobileMenuIcon />
        </NavLink>
        <a className='navbar-logo' href='/'>
          <img src={MuntraLogo} className='muntra-logo' alt='logo' />
        </a>
      </div>

      <div className='navbar-right'>
        <div className='user d-inline-block'>
          <UncontrolledDropdown className='dropdown-menu-right'>
            <DropdownToggle className='p-0 user-btn' color='empty'>
              <div className='text-container'>
                {user?.attributes && (
                  <div className='name mr-2'>
                    <b>{userFullName}</b>
                  </div>
                )}
                {lab?.attributes && (
                  <div className='name mr-2' style={{ fontWeight: "normal" }}>
                    {lab.attributes?.lab_name}
                  </div>
                )}
              </div>
              <div className='user-icon'>
                <UserIcon />
              </div>
            </DropdownToggle>
            <DropdownMenu className='mt-3' right>
              <DropdownItem onClick={() => onRouteChange("/users")}>
                Users
              </DropdownItem>
              <DropdownItem onClick={() => onRouteChange("/price-lists")}>
                Price lists
              </DropdownItem>
              <DropdownItem onClick={handleLogout}>Log out</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </div>
    </nav>
  );
};

export default memo(Header);
