const TYPES = {
  AUTO_START_TOKEN_REFRESH: "AUTO_START_TOKEN_REFRESH",
  LOGIN_USER: "LOGIN_USER",
  LOGIN_USER_SUCCESS: "LOGIN_USER_SUCCESS",
  LOGIN_USER_ERROR: "LOGIN_USER_ERROR",
  LOGIN_MOBILE_USER: "LOGIN_MOBILE_USER",
  LOGIN_MOBILE_USER_SUCCESS: "LOGIN_MOBILE_USER_SUCCESS",
  LOGIN_MOBILE_USER_ERROR: "LOGIN_MOBILE_USER_ERROR",
  GET_LABS: "GET_LABS",
  GET_LABS_SUCCESS: "GET_LABS_SUCCESS",
  GET_LABS_ERROR: "GET_LABS_ERROR",
  GET_LAB_BY_ID: "GET_LAB_BY_ID",
  GET_LAB_BY_ID_SUCCESS: "GET_LAB_BY_ID_SUCCESS",
  GET_LAB_BY_ID_ERROR: "GET_LAB_BY_ID_ERROR",
  GET_CURRENT_USER: "GET_CURRENT_USER",
  LOGOUT_USER: "LOGOUT_USER",
  GET_VAT_CODES: "GET_VAT_CODES",
  USER_SIGN_IN_QR_CODE: "USER_SIGN_IN_QR_CODE",
  USER_SIGN_IN_QR_CODE_ERROR: "USER_SIGN_IN_QR_CODE_ERROR",
  VAT_CODES_REFRESH: "VAT_CODES_REFRESH",
};

export const authActions = {
  ...TYPES,
  autoStartTokenRefresh: (data) => ({
    type: TYPES.AUTO_START_TOKEN_REFRESH,
    payload: data,
  }),
  loginUser: (formData) => ({
    type: TYPES.LOGIN_USER,
    payload: { formData },
  }),
  loginUserSuccess: (user) => ({
    type: TYPES.LOGIN_USER_SUCCESS,
    payload: user,
  }),
  loginUserError: () => ({
    type: TYPES.LOGIN_USER_ERROR,
  }),
  getLabById: (data) => ({
    type: TYPES.GET_LAB_BY_ID,
    payload: data,
  }),
  getLabByIdSuccess: (payload) => ({
    type: TYPES.GET_LAB_BY_ID_SUCCESS,
    payload,
  }),
  getLabByIdError: () => ({
    type: TYPES.GET_LAB_BY_ID_ERROR,
  }),
  getLabs: () => ({
    type: TYPES.GET_LABS,
  }),
  getLabsSuccess: (lab) => ({
    type: TYPES.GET_LABS_SUCCESS,
    payload: lab,
  }),
  getLabsError: () => ({
    type: TYPES.GET_LABS_ERROR,
  }),
  loginMobileUser: ({
    slotsId,
    clinicId,
    selectedSlot,
    isSkicka,
    pathName,
  }) => ({
    type: TYPES.LOGIN_MOBILE_USER,
    payload: { slotsId, clinicId, selectedSlot, isSkicka, pathName },
  }),
  loginMobileUserSuccess: (data) => ({
    type: TYPES.LOGIN_MOBILE_USER_SUCCESS,
    payload: data,
  }),
  loginMobileUserError: () => ({
    type: TYPES.LOGIN_MOBILE_USER_ERROR,
  }),
  getCurrentUser: () => ({
    type: TYPES.GET_CURRENT_USER,
  }),
  logoutUser: (history) => ({
    type: TYPES.LOGOUT_USER,
    payload: { history },
  }),
  getVatCodes: (payload) => ({
    type: TYPES.GET_VAT_CODES,
    payload,
  }),
  userSignInQrCode: (payload) => ({
    type: TYPES.USER_SIGN_IN_QR_CODE,
    payload,
  }),
  userSignInQrCodeError: () => ({
    type: TYPES.USER_SIGN_IN_QR_CODE_ERROR,
  }),
  vatCodesRefresh: (payload) => ({
    type: TYPES.VAT_CODES_REFRESH,
    payload,
  }),
};
