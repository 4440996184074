import { invoicesActions as actions } from "./actions";

const initialState = {
  loading: false,
  invoicesUI: {
    perPage: 10,
    page: 1,
    sortOrder: "desc",
  },
  invoices: [],
  currentInvoice: {},
  invoicesPagination: {},
  isCreateInvoiceModalShown: false,
};

export const invoicesReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case actions.INVOICES_RELOAD:
    case actions.CURRENT_INVOICE_RELOAD:
      return {
        ...state,
        loading: true,
      };
    case actions.INVOICES_REFRESH: {
      return {
        ...state,
        invoices: action.payload.data,
        invoicesPagination: action.payload.pagination,
      };
    }
    case actions.INVOICES_UI_REFRESH:
      return {
        ...state,
        invoicesUI: { ...state.invoicesUI, ...action.payload },
      };
    case actions.CURRENT_INVOICE_REFRESH: {
      return {
        ...state,
        currentInvoice: action.payload,
      };
    }
    case actions.LOADING_ERROR:
    case actions.LOADING_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    default:
      return { ...state };
  }
};
