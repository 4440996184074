export const API_ROUTES = {
  errorNotifications: "/error-notifications",
  labDentalLabSupplierInvoices: "/lab-dental-lab-supplier-invoices",
  labDentalLabs: "/lab-dental-labs",
  labDentalLabOrders: "/lab-dental-lab-orders",
  labDentalLabOrderLines: "/lab-dental-lab-order-lines",
  labDentalLabOrderResponses: "/lab-dental-lab-order-responses",
  labDentalLabDeliveries: "/lab-dental-lab-deliveries",
  labUsers: "/lab-users",
  vatCodes: "/vat-codes",
  labPrices: "/lab-prices",
  labFiles: "/lab-files",
  labPriceLists: "/lab-price-lists",
};

export const QUERY_PARAMS = {
  orderResponseId: "lab_dental_lab_order_response_id",
  orderId: "lab_dental_lab_order_id",
};
