import { deliveriesActions as actions } from "./actions";

const initialState = {
  deliveries: [],
  pagination: {},
  deliveriesUI: {
    perPage: 10,
    page: 1,
  },
  loading: false,
  checkedDeliveries: [],
};

export const deliveriesReducer = (
  state = initialState,
  { type, payload } = {}
) => {
  switch (type) {
    case actions.GET_DELIVERIES:
    case actions.INVOICE_SAVE:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_DELIVERIES_SUCCESS:
      return {
        ...state,
        loading: false,
        deliveries: payload.data,
        pagination: payload.pagination,
      };
    case actions.GET_DELIVERIES_ERROR:
      return {
        ...state,
        loading: false,
        deliveries: initialState.deliveries,
        pagination: initialState.pagination,
      };
    case actions.DELIVERIES_REFRESH:
      return {
        ...state,
        deliveries: payload.data,
      };
    case actions.DELIVERIES_UI_REFRESH:
      return {
        ...state,
        deliveriesUI: { ...state.deliveriesUI, ...payload },
      };
    case actions.CHECKED_DELIVERIES_REFRESH:
      return {
        ...state,
        checkedDeliveries: payload,
      };
    case actions.LOADING_SUCCESS:
    case actions.LOADING_ERROR:
      return {
        ...state,
        loading: false,
      };
    default:
      return { ...state };
  }
};
