import { ORDER_STATUS } from "../constants/orders";

export const checkAllItemsInvoicedInDeliveries = (deliveries = []) => {
  if (!deliveries.length) {
    return false;
  }

  return deliveries.every((delivery) => {
    if (!delivery.dental_lab_order_lines.length) {
      return false;
    }
    return delivery.dental_lab_order_lines.every((item) => {
      return item?.dental_lab_supplier_invoice_row?.id;
    });
  });
};

export const checkAllDeliveriesDelivered = (deliveries = []) => {
  if (!deliveries.length) {
    return false;
  }

  return deliveries.every((delivery) => {
    return delivery.dental_lab_order_lines?.length;
  });
};

export const getOrderStatus = (order, deliveries, isItemsExist) => {
  const { closed_by_lab_at: closedByLabAt, accepted_at: acceptedAt } =
    order?.attributes || {};

  if (closedByLabAt) {
    return ORDER_STATUS.closed;
  }

  const isAllInvoiced = checkAllItemsInvoicedInDeliveries(deliveries);
  if (isAllInvoiced && !isItemsExist) {
    return ORDER_STATUS.invoiced;
  }

  const isAllDelivered = checkAllDeliveriesDelivered(deliveries);
  if (isAllDelivered && !isItemsExist) {
    return ORDER_STATUS.delivered;
  }

  if (isItemsExist) {
    return ORDER_STATUS.inProgress;
  }

  if (acceptedAt) {
    return ORDER_STATUS.accepted;
  }

  return ORDER_STATUS.notYetAccepted;
};

export const getOrderStatusFromOrderList = (order) => {
  const { closed_by_lab_at: closedByLabAt, accepted_at: acceptedAt } =
    order?.attributes || {};
  const {
    dental_lab_deliveries: deliveries = [],
    dental_lab_order_lines: orderLines = [],
  } = order;

  const isItemsExist = orderLines.length;
  const isDeliveriesExist = deliveries.length;
  if (closedByLabAt) {
    return ORDER_STATUS.closed;
  }

  const isAllInvoiced = orderLines.every((item) => {
    return item.dental_lab_supplier_invoice_row?.id;
  });
  if (isAllInvoiced && isItemsExist) {
    return ORDER_STATUS.invoiced;
  }

  const isAllItemsDelivered = orderLines.every((item) => {
    return item.dental_lab_delivery?.id;
  });

  if (isDeliveriesExist && isItemsExist && isAllItemsDelivered) {
    return ORDER_STATUS.delivered;
  }

  if (isItemsExist && !isAllItemsDelivered) {
    return ORDER_STATUS.inProgress;
  }

  if (acceptedAt) {
    return ORDER_STATUS.accepted;
  }

  return ORDER_STATUS.notYetAccepted;
};

export const addOrdersStatus = (orders) => {
  return orders.map((order) => {
    const orderStatus = getOrderStatusFromOrderList(order);
    return { ...order, status: orderStatus };
  });
};
