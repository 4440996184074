import React from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { addMinutes, isBefore, format } from "date-fns";

import { CUSTOM_EVENTS } from "../../constants/customEvents";
import { FORMATS } from "../../constants/formats";
import { eventEmitter } from "../../lib/event-bus";
import config from "../../config";
import { handleResponseError } from "../../helpers/handle-response-error";
import ToasterMessage from "../../components/common/toaster/toaster-message";

export class BaseService {
  constructor(url = "", api = "") {
    this.baseServiceURL = api ? `${api}${url}` : `${config.api}${url}`;
    this.processError = this.processError.bind(this);
  }

  get baseURL() {
    return this.baseServiceURL;
  }

  get agent() {
    const headers = {};
    const accessToken = localStorage.getItem("user_token_lab_frontend");
    if (accessToken) {
      headers.authorization = `Bearer ${accessToken}`;
    }

    const axiosInstance = axios.create({
      baseURL: this.baseURL,
      headers,
    });

    axiosInstance.interceptors.response.use(
      (response) => {
        localStorage.setItem(
          "expired_time",
          format(addMinutes(new Date(), 120), FORMATS.dateTimeLocalSeconds)
        );
        return response;
      },
      (error) => {
        if (error.response && +error.response.status === 401) {
          eventEmitter.emit(CUSTOM_EVENTS.logout);
          throw error;
        } else throw error;
      }
    );

    axiosInstance.interceptors.request.use(
      (config) => {
        const expTime = localStorage.getItem("expired_time");
        const token = localStorage.getItem("user_token_lab_frontend");
        const isTimeExpired = isBefore(new Date(expTime), new Date());

        if (expTime && token && isTimeExpired) {
          eventEmitter.emit(CUSTOM_EVENTS.logout);
          return Promise.reject(new Error("The session has expired."));
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    return axiosInstance;
  }

  // eslint-disable-next-line class-methods-use-this
  processError(error) {
    toast.error(<ToasterMessage message={handleResponseError(error)} />);
  }
}
