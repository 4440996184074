import { applyMiddleware, compose, createStore, combineReducers } from "redux";
import createSagaMiddleware from "redux-saga";
import { createBrowserHistory } from "history";
import { connectRouter, routerMiddleware } from "connected-react-router";
import rootReducer from "./root-reducer";
import sagas from "./sagas";

const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const routeMiddleware = routerMiddleware(history);

const middleware = [sagaMiddleware, routeMiddleware];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducerWithRouter = combineReducers({
  ...rootReducer,
  router: connectRouter(history),
});

const store = createStore(
  rootReducerWithRouter,
  composeEnhancers(applyMiddleware(...middleware))
);

sagaMiddleware.run(sagas);

export { history, store };
