import { createSelector } from "reselect";

const deliveries = ({ deliveries }) => deliveries;
const deliveriesList = ({ deliveries }) => deliveries.deliveries;
const deliveriesUI = ({ deliveries }) => deliveries.deliveriesUI;
const checkedDeliveries = ({ deliveries }) => deliveries.checkedDeliveries;

export const selectDeliveries = createSelector(
  [deliveries],
  (deliveries) => deliveries
);

export const selectDeliveriesList = createSelector(
  [deliveriesList],
  (deliveriesList) => deliveriesList
);

export const selectDeliveriesIds = createSelector(
  [deliveriesList],
  (deliveriesList) => deliveriesList.map((item) => item.id)
);

export const selectDeliveriesUI = createSelector(
  [deliveriesUI],
  (deliveriesUI) => deliveriesUI
);

export const selectCheckedDeliveries = createSelector(
  [checkedDeliveries],
  (checkedDeliveries) => checkedDeliveries
);

export const selectCheckedDeliveriesIdLength = createSelector(
  [selectCheckedDeliveries],
  (selectCheckedDeliveries) => selectCheckedDeliveries.length
);
