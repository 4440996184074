import { createSelector } from "reselect";

const prices = ({ prices }) => prices;
const currentPrice = ({ prices }) => prices.currentPrice;

export const selectPrices = createSelector([prices], (prices) => prices);

export const selectCurrentPrice = createSelector(
  [currentPrice],
  (currentPrice) => currentPrice
);
