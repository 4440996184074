export const createPriceData = ({
  priceCode,
  description,
  total,
  priceListId,
  currencyId,
  vatCodeId,
}) => ({
  type: "lab_price",
  attributes: {
    price_code: priceCode,
    description,
    total_excluding_vat: total,
  },
  relationships: {
    price_list: {
      data: {
        type: "lab_price_list",
        id: priceListId,
      },
    },
    currency: {
      data: {
        id: currencyId,
      },
    },
    vat_code: {
      data: {
        id: vatCodeId,
      },
    },
  },
});

export const createItemData = ({ text, price, vatCodeId }) => ({
  type: "lab_dental_lab_order_line",
  attributes: {
    text,
    price_excluding_vat_per_quantity: price || 0,
    vat_code_id: vatCodeId,
  },
});

export const createDeliveryData = ({ orderId, orderLines }) => ({
  data: {
    type: "lab_dental_lab_delivery",
    attributes: {
      dispatched_at: new Date().toISOString(),
      received_at: null,
      created_at: null,
    },
    relationships: {
      dental_lab_order: {
        data: {
          type: "lab_dental_lab_order",
          id: orderId,
        },
      },
      dental_lab_order_lines: {
        data: [
          ...orderLines.map((id) => ({
            type: "lab_dental_lab_order_line",
            id,
          })),
        ],
      },
    },
  },
});
