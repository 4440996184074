import { createSelector } from "reselect";

const authUser = ({ authUser }) => authUser;
const autoStartToken = ({ authUser }) => authUser.autoStartToken;
const vatCodes = ({ authUser }) => authUser.vatCodes;
const currency = ({ authUser }) => authUser.currency;

export const selectAuthUser = createSelector(
  [authUser],
  (authUser) => authUser
);

export const selectAutoStartToken = createSelector(
  [autoStartToken],
  (autoStartToken) => autoStartToken
);

export const selectLabId = createSelector(
  [selectAuthUser],
  (authUser) => authUser?.lab?.id
);

export const selectSupplierId = createSelector(
  [selectAuthUser],
  (authUser) => authUser?.supplier?.id
);

export const selectVatCodes = createSelector(
  [vatCodes],
  (vatCodes) => vatCodes
);

export const selectCurrencyId = createSelector(
  [currency],
  (currency) => currency?.id
);
