import { all, call, put, takeLatest, select } from "redux-saga/effects";
import toast from "react-hot-toast";

import { PriceListsService } from "../../services/PriceListsService";
import { priceListsActions } from "./actions";
import { getItemFields } from "../../helpers/get-item-fields";
import { selectPriceLists } from "./selectors";
import { selectLabId, selectSupplierId } from "../auth/selectors";

function* getPriceLists({ payload }) {
  try {
    const response = yield call(PriceListsService.getPriceLists, payload);
    if (response?.data?.data) {
      const results = response.data.data.map((item) => {
        const { relationships, ...rest } = item;
        const result = getItemFields(relationships, response.data.included);
        return { ...result, ...rest };
      });

      yield put(
        priceListsActions.getPriceListsSuccess({
          data: results,
          pagination: response.data.meta?.pagination || {},
        })
      );
    }
  } catch (error) {
    yield put(priceListsActions.getPriceListsError());
  }
}

function* priceListSave({ payload }) {
  const { name } = payload;

  if (!name) {
    toast.error("The name field is required");
    return;
  }

  const labId = yield select(selectLabId);
  const supplierId = yield select(selectSupplierId);

  const data = {
    type: "lab_price_list",
    attributes: {
      name,
      deleted: false,
      used: true,
    },
  };

  const response = yield call(
    PriceListsService.savePriceList,
    data,
    supplierId
  );

  if (!response?.data) {
    return;
  }

  yield put(priceListsActions.hideAddPriceListModal());
  yield put(
    priceListsActions.getPriceLists({
      per_page: 10,
      page: 1,
      lab_id: labId,
      order: "desc",
    })
  );
}

function* priceListDelete({ payload }) {
  const { id } = payload;

  const labId = yield select(selectLabId);

  const response = yield call(PriceListsService.deletePriceList, id);
  if (!response?.data) {
    return;
  }

  yield put(
    priceListsActions.getPriceLists({
      per_page: 10,
      page: 1,
      lab_id: labId,
      order: "desc",
    })
  );
}

function* priceListUpdate({ payload }) {
  const { id, priceList } = payload;

  const supplierId = yield select(selectSupplierId);

  const response = yield call(
    PriceListsService.updatePriceList,
    id,
    supplierId,
    priceList
  );
  if (!response?.data) {
    return;
  }

  yield put(priceListsActions.hideAddPriceListModal());

  const { priceLists } = yield select(selectPriceLists);
  const priceListsCopy = structuredClone(priceLists);
  const idx = priceListsCopy.findIndex((price) => price.id === id);
  priceListsCopy[idx].attributes = response.data.data.attributes;

  yield put(priceListsActions.priceListsRefresh(priceListsCopy));
}

export default function* userSaga() {
  yield all([
    takeLatest(priceListsActions.GET_PRICE_LISTS, getPriceLists),
    takeLatest(priceListsActions.PRICE_LIST_SAVE, priceListSave),
    takeLatest(priceListsActions.PRICE_LIST_DELETE, priceListDelete),
    takeLatest(priceListsActions.PRICE_LIST_UPDATE, priceListUpdate),
  ]);
}
