import {
  MENU_SET_CLASSNAMES,
  MENU_CLICK_MOBILE_MENU,
} from "../../constants/action-types";

export const clickOnMobileMenu = (strCurrentClasses) => {
  const currentClasses = strCurrentClasses
    ? strCurrentClasses
        .split(" ")
        .filter((x) => x !== "" && x !== "sub-show-temporary")
    : "";

  let nextClasses = "";
  if (currentClasses.includes("main-show-temporary")) {
    nextClasses = currentClasses
      .filter((x) => x !== "main-show-temporary")
      .join(" ");
  } else {
    nextClasses = `${currentClasses.join(" ")} main-show-temporary`;
  }

  return {
    type: MENU_CLICK_MOBILE_MENU,
    payload: { containerClassnames: nextClasses },
  };
};

export const setContainerClassnames = (strCurrentClasses) => {
  const currentClasses = strCurrentClasses
    ? strCurrentClasses.split(" ").filter((x) => x !== "")
    : "";

  let nextClasses = "";
  if (
    currentClasses.includes("menu-default") &&
    currentClasses.includes("menu-sub-hidden")
  ) {
    nextClasses = "menu-default menu-sub-hidden";
  } else if (currentClasses.includes("menu-default")) {
    nextClasses = "menu-default";
  } else if (currentClasses.includes("menu-sub-hidden")) {
    nextClasses = "menu-sub-hidden";
  } else if (currentClasses.includes("menu-hidden")) {
    nextClasses = "menu-hidden";
  }

  if (currentClasses.includes("menu-mobile")) {
    nextClasses += " menu-mobile";
  }

  return {
    type: MENU_SET_CLASSNAMES,
    payload: { containerClassnames: nextClasses },
  };
};
