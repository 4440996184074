import { BaseService } from "../BaseService";
import { API_ROUTES } from "../../constants/routes";

class UsersService extends BaseService {
  constructor(url) {
    super(url);
    this.getUser = this.getUser.bind(this);
    this.getUserList = this.getUserList.bind(this);
  }

  async getUser() {
    try {
      const response = await this.agent.get("/current");

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      throw error;
    }
  }

  async getUserList({ labId, perPage, page }) {
    try {
      const response = await this.agent.get("", {
        params: {
          dental_lab_id: labId,
          order: "asc",
          page,
          per_page: perPage,
          sort_by: "name",
        },
      });

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      throw error;
    }
  }
}

const ServiceInstance = new UsersService(API_ROUTES.labUsers);

export default ServiceInstance;
export { ServiceInstance as UsersService };
