import { BaseService } from "../BaseService";
import { API_ROUTES } from "../../constants/routes";

class LabsService extends BaseService {
  constructor(url) {
    super(url);
    this.getLabs = this.getLabs.bind(this);
    this.getCurrentLab = this.getCurrentLab.bind(this);
  }

  async getLabs() {
    try {
      const response = await this.agent.get("");

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      throw error;
    }
  }

  async getCurrentLab(id) {
    try {
      const response = await this.agent.get(`/${id}`, {
        params: {
          include: "country.currencies,supplier",
        },
      });

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      throw error;
    }
  }
}

const ServiceInstance = new LabsService(API_ROUTES.labDentalLabs);

export default ServiceInstance;
export { ServiceInstance as LabsService };
