import { createSelector } from "reselect";

const dashboard = ({ dashboard }) => dashboard;
const loading = ({ dashboard }) => dashboard.loading;
const ordersUI = ({ dashboard }) => dashboard.ordersUI;
const fileLoadingIds = ({ dashboard }) => dashboard.fileLoadingIds;
const currentOrder = ({ dashboard }) => dashboard.currentOrder;
const items = ({ dashboard }) => dashboard.items;
const checkedItemsId = ({ dashboard }) => dashboard.checkedItemsId;
const deliveries = ({ dashboard }) => dashboard.deliveries;
const priceListId = ({ dashboard }) => dashboard.priceListId;
const searchPrices = ({ dashboard }) => dashboard.searchPrices;

export const selectDashboard = createSelector(
  [dashboard],
  (dashboard) => dashboard
);

export const selectLoading = createSelector([loading], (loading) => loading);

export const selectCurrentOrder = createSelector(
  [currentOrder],
  (currentOrder) => currentOrder
);

export const selectOrdersUI = createSelector(
  [ordersUI],
  (ordersUI) => ordersUI
);

export const selectFileLoadingIds = createSelector(
  [fileLoadingIds],
  (fileLoadingIds) => fileLoadingIds
);

export const selectItems = createSelector([items], (items) => items);

export const selectIsItemsExist = createSelector([items], (items) =>
  Boolean(items.length)
);

export const selectItemsId = createSelector([items], (items) =>
  items.map((item) => item.id)
);

export const selectCheckedItemsId = createSelector(
  [checkedItemsId],
  (checkedItemsId) => checkedItemsId
);

export const selectDeliveries = createSelector(
  [deliveries],
  (deliveries) => deliveries
);

export const selectPriceListId = createSelector(
  [priceListId],
  (priceListId) => priceListId
);

export const selectSearchPrices = createSelector(
  [searchPrices],
  (searchPrices) => searchPrices
);
