import { authActions as actions } from "./actions";

const initialState = {
  autoStartToken: {},
  labs: null,
  lab: null,
  currency: {},
  country: {},
  supplier: {},
  vatCodes: [],
  user: null,
  loading: false,
  loadingLabs: false,
  loadingLab: false,
};

export const authReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case actions.AUTO_START_TOKEN_REFRESH:
      return {
        ...state,
        autoStartToken: action.payload,
      };
    case actions.LOGIN_USER:
    case actions.LOGIN_MOBILE_USER:
    case actions.USER_SIGN_IN_QR_CODE:
      return {
        ...state,
        loading: true,
      };
    case actions.LOGIN_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
      };
    case actions.LOGIN_USER_ERROR:
      return {
        ...state,
        loading: false,
        user: "",
      };
    case actions.LOGIN_MOBILE_USER_SUCCESS:
    case actions.LOGIN_MOBILE_USER_ERROR:
    case actions.USER_SIGN_IN_QR_CODE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case actions.GET_LABS:
      return {
        ...state,
        loadingLabs: true,
      };
    case actions.GET_LABS_SUCCESS:
      return {
        ...state,
        labs: action.payload,
        loadingLabs: false,
      };
    case actions.GET_LABS_ERROR:
      return {
        ...state,
        loadingLabs: false,
      };
    case actions.GET_LAB_BY_ID:
      return {
        ...state,
        loadingLab: true,
      };
    case actions.GET_LAB_BY_ID_SUCCESS:
      return {
        ...state,
        lab: action.payload.lab,
        currency: action.payload.currency,
        country: action.payload.country,
        supplier: action.payload.supplier,
        loadingLab: false,
      };
    case actions.GET_LAB_BY_ID_ERROR:
      return {
        ...state,
        loadingLab: false,
      };
    case actions.VAT_CODES_REFRESH:
      return {
        ...state,
        vatCodes: action.payload,
      };
    case actions.LOGOUT_USER:
      localStorage.removeItem("user_token_lab_frontend");
      localStorage.removeItem("expired_time");
      return { ...state, user: null, error: "" };
    default:
      return { ...state };
  }
};
