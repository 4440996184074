import { all, call, put, takeEvery } from "redux-saga/effects";
import { UsersService } from "../../services/UsersService";
import { userActions } from "./actions";

function* getUser() {
  try {
    const user = yield call(UsersService.getUser);
    if (user.data) {
      const results = user.data.data;
      yield put(userActions.getUserSuccess(results));
    }
  } catch (error) {
    yield put(userActions.getUserError());
  }
}

function* getLabDentalLabUsers({ payload }) {
  try {
    const user = yield call(UsersService.getUserList, payload);
    if (user.data) {
      const {
        data,
        meta: { pagination },
      } = user.data;

      yield put(userActions.getLabDentalLabUserSuccess({ data, pagination }));
    }
  } catch (error) {
    yield put(userActions.getLabDentalLabUserError());
  }
}

export default function* userSaga() {
  yield all([
    takeEvery(userActions.GET_USER, getUser),
    takeEvery(userActions.GET_LAB_DENTAL_LAB_USERS, getLabDentalLabUsers),
  ]);
}
