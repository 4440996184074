import React from "react";

const OrderItemsIcon = () => (
  <svg
    viewBox='0 0 1024 1024'
    xmlns='http://www.w3.org/2000/svg'
    style={{ width: "16px", height: "16px", marginBottom: "5px" }}
  >
    <path d='M426.667 170.667h426.666a42.667 42.667 0 0 1 0 85.333H426.667a42.667 42.667 0 1 1 0-85.333zm0 298.666h426.666a42.667 42.667 0 0 1 0 85.334H426.667a42.667 42.667 0 0 1 0-85.334zm0 298.667h426.666a42.667 42.667 0 0 1 0 85.333H426.667a42.667 42.667 0 0 1 0-85.333zM213.333 298.667a85.333 85.333 0 1 1 0-170.667 85.333 85.333 0 0 1 0 170.667zm0 298.666a85.333 85.333 0 1 1 0-170.666 85.333 85.333 0 0 1 0 170.666zm0 298.667a85.333 85.333 0 1 1 0-170.667 85.333 85.333 0 0 1 0 170.667z' />
  </svg>
);

export default OrderItemsIcon;
