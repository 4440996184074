import { all, call, put, takeEvery } from "redux-saga/effects";
import { SEND_ERROR_REPORT } from "../../constants/action-types";
import { ErrorNotificationsService } from "../../services/ErrorNotificationsService";
import { sendErrorReportSuccess, sendErrorReportError } from "./actions";
import { handleResponseError } from "../../helpers/handle-response-error";

function* sendErrorReport({ payload }) {
  try {
    const { data: response } = yield call(
      ErrorNotificationsService.sendErrorReport,
      payload
    );
    yield put(sendErrorReportSuccess(response));
  } catch (error) {
    yield put(sendErrorReportError(handleResponseError(error)));
  }
}

export default function* errorReportingSaga() {
  yield all([takeEvery(SEND_ERROR_REPORT, sendErrorReport)]);
}
