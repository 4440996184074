import { STATUS as status } from "../constants/deliveries";

export const getOrderItemIds = (checkedList, deliveriesList) => {
  const deliveriesItemsIds = deliveriesList.reduce((accum, delivery) => {
    const idList = delivery.dental_lab_order_lines.map(
      (orderLine) => orderLine.id
    );
    accum[delivery.id] = idList;
    return accum;
  }, {});

  return checkedList.map((id) => deliveriesItemsIds[id]).flat();
};

export const addDeliveryStatus = (deliveries = []) => {
  return deliveries.map((delivery) => {
    const isAllDelivered = delivery.dental_lab_order_lines.every((item) => {
      return item.dental_lab_supplier_invoice_row?.id;
    });
    if (delivery.dental_lab_order_lines.length && isAllDelivered) {
      return { ...delivery, status: status.invoiced };
    }

    const isSomeDelivered = delivery.dental_lab_order_lines.some((item) => {
      return item.dental_lab_supplier_invoice_row?.id;
    });
    if (isSomeDelivered) {
      return { ...delivery, status: status.partlyInvoiced };
    }

    if (delivery.attributes.received_at) {
      return { ...delivery, status: status.received };
    }
    if (delivery.attributes.dispatched_at) {
      return { ...delivery, status: status.sent };
    }

    return { ...delivery, status: status.draft };
  });
};
