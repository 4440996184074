import { BaseService } from "../BaseService";
import { API_ROUTES } from "../../constants/routes";

class SupplierInvoicesService extends BaseService {
  constructor(url) {
    super(url);
    this.getInvoices = this.getInvoices.bind(this);
    this.getCurrentInvoice = this.getCurrentInvoice.bind(this);
    this.saveInvoice = this.saveInvoice.bind(this);
  }

  async getInvoices({ page, perPage, sortOrder, labId }) {
    try {
      const response = await this.agent.get("", {
        params: {
          dental_lab_id: labId,
          sort_by: "id",
          order: "desc",
          page,
          per_page: perPage,
          sort_order: sortOrder,
          include:
            "supplier_invoice_rows.vat_code,supplier_payments_per_invoices.supplier_payment.verification,supplier_payments_per_invoices.verification,verification",
        },
      });

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      throw error;
    }
  }

  async getCurrentInvoice(invoiceId, labId) {
    try {
      const response = await this.agent.get(`/${invoiceId}`, {
        params: {
          dental_lab_id: labId,
          include:
            "currency,customer,fiscal_year,supplier_invoice_rows.vat_code,supplier_payments_per_invoices.supplier_payment.verification,supplier_payments_per_invoices.verification,verification",
        },
      });

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      throw error;
    }
  }

  async saveInvoice(params) {
    try {
      const response = await this.agent.post("", null, {
        params,
      });

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      return null;
    }
  }
}

const ServiceInstance = new SupplierInvoicesService(
  API_ROUTES.labDentalLabSupplierInvoices
);

export default ServiceInstance;
export { ServiceInstance as SupplierInvoicesService };
