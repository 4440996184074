import { pricesActions as actions } from "./actions";

const initialState = {
  prices: [],
  pagination: {},
  currentPrice: {},
  loading: false,
  isAddPriceModalShow: false,
};

export const pricesReducer = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case actions.GET_PRICES:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_PRICES_SUCCESS:
      return {
        ...state,
        loading: false,
        prices: payload.data,
        pagination: payload.pagination,
      };
    case actions.GET_PRICES_ERROR:
      return {
        ...state,
        loading: false,
        prices: initialState.prices,
        pagination: initialState.pagination,
      };
    case actions.PRICES_REFRESH:
      return {
        ...state,
        prices: payload.prices,
      };
    case actions.ADD_PRICE_MODAL_SHOW:
      return {
        ...state,
        isAddPriceModalShow: true,
      };
    case actions.ADD_PRICE_MODAL_HIDE:
      return {
        ...state,
        isAddPriceModalShow: false,
      };
    case actions.CURRENT_PRICE_REFRESH:
      return {
        ...state,
        currentPrice: payload.price,
      };
    default:
      return { ...state };
  }
};
