import { orderItemsActions as actions } from "./actions";

const initialState = {
  orderItemsList: [],
  orderItemsUI: {
    perPage: 10,
    page: 1,
  },
  loading: false,
  pagination: {},
  checkedOrderItems: [],
};

export const orderItemsReducer = (
  state = initialState,
  { type, payload } = {}
) => {
  switch (type) {
    case actions.ORDER_ITEMS_REFRESH:
      return {
        ...state,
        orderItemsList: payload,
      };
    case actions.GET_ORDER_ITEMS:
    case actions.INVOICE_SAVE:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_ORDER_ITEMS_SUCCESS:
      return {
        ...state,
        loading: false,
        orderItemsList: payload.data,
        pagination: payload.pagination,
        checkedOrderItems: initialState.checkedOrderItems,
      };
    case actions.CHECKED_ORDER_ITEMS_REFRESH:
      return {
        ...state,
        checkedOrderItems: payload,
      };
    case actions.ORDER_ITEMS_UI_REFRESH:
      return {
        ...state,
        orderItemsUI: { ...state.orderItemsUI, ...payload },
      };
    case actions.LOADING_SUCCESS:
    case actions.LOADING_ERROR:
      return {
        ...state,
        loading: false,
      };
    default:
      return { ...state };
  }
};
