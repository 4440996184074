import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { format, addDays } from "date-fns";
import toast from "react-hot-toast";

import { NOTIFICATIONS } from "../../constants/notifications";
import { getOrderItemIds, addDeliveryStatus } from "../../helpers/deliveries";
import { DeliveriesService } from "../../services/DeliveriesService";
import { SupplierInvoicesService } from "../../services/SupplierInvoicesService";
import { deliveriesActions as actions } from "./actions";
import { selectAuthUser } from "../auth/selectors";
import { selectQuery } from "../router/selectors";
import {
  selectCheckedDeliveries,
  selectDeliveriesIds,
  selectDeliveriesList,
} from "./selectors";
import { getItemFields } from "../../helpers/get-item-fields";

function* getDeliveries({ payload }) {
  const response = yield call(DeliveriesService.getDeliveries, payload);
  if (!response?.data) {
    yield put(actions.getDeliveriesError());
    return;
  }

  const results = response.data.data.map((item) => {
    const { relationships, ...rest } = item;
    const result = getItemFields(relationships, response.data.included);
    return { ...result, ...rest };
  });

  const deliveriesWithStatus = addDeliveryStatus(results);
  yield put(
    actions.getDeliveriesSuccess({
      data: deliveriesWithStatus,
      pagination: response.data.meta?.pagination || {},
    })
  );
}

function* deliveriesCheckChange(action) {
  const { id, checked } = action.payload;

  const checkedDeliveries = yield select(selectCheckedDeliveries);
  const resDeliveriesIds = structuredClone(checkedDeliveries);

  if (checked && !resDeliveriesIds.includes(id)) {
    resDeliveriesIds.push(id);
  }
  if (!checked && resDeliveriesIds.includes(id)) {
    resDeliveriesIds.splice(resDeliveriesIds.indexOf(id), 1);
  }

  yield put(actions.checkedDeliveriesRefresh(resDeliveriesIds));
}

function* allDeliveriesCheckChange(action) {
  const checked = action.payload;

  const deliveriesIds = yield select(selectDeliveriesIds);
  const checkedDeliveriesResult = checked ? deliveriesIds : [];

  yield put(actions.checkedDeliveriesRefresh(checkedDeliveriesResult));
}

function* invoiceSave() {
  const checkedDeliveriesId = yield select(selectCheckedDeliveries);

  if (!checkedDeliveriesId.length) {
    yield put(actions.loadingError());
    toast.error(NOTIFICATIONS.selectOrderItem);
    return;
  }

  const { lab, currency } = yield select(selectAuthUser);
  const deliveriesList = yield select(selectDeliveriesList);
  const orderItemsIds = getOrderItemIds(checkedDeliveriesId, deliveriesList);
  const dueDate = addDays(new Date(), 30);

  const params = {
    dental_lab_id: lab?.id,
    currency_id: currency?.id,
    dental_lab_order_line_ids: orderItemsIds.length
      ? orderItemsIds.join(",")
      : null,
    invoice_date: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
    due_date: format(dueDate, "yyyy-MM-dd HH:mm:ss"),
  };

  const response = yield call(SupplierInvoicesService.saveInvoice, params);
  if (!response?.data) {
    yield put(actions.loadingError());
    return;
  }

  const query = yield select(selectQuery);
  yield put(actions.loadingSuccess());
  yield put(actions.checkedDeliveriesRefresh([]));
  yield put(actions.getDeliveries(query));
}

export default function* userSaga() {
  yield all([takeLatest(actions.GET_DELIVERIES, getDeliveries)]);
  yield all([
    takeLatest(actions.DELIVERIES_CHECK_CHANGE, deliveriesCheckChange),
  ]);
  yield all([
    takeLatest(actions.ALL_DELIVERIES_CHECK_CHANGE, allDeliveriesCheckChange),
  ]);
  yield all([takeLatest(actions.INVOICE_SAVE, invoiceSave)]);
}
