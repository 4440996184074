import { priceListsActions as actions } from "./actions";

const initialState = {
  priceLists: [],
  priceListsUI: {
    perPage: 10,
    page: 1,
    sortOrder: "desc",
  },
  pagination: {},
  currentPriceList: {},
  loading: false,
  isAddPriceListModalShow: false,
};

export const priceListsReducer = (
  state = initialState,
  { type, payload } = {}
) => {
  switch (type) {
    case actions.GET_PRICE_LISTS:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_PRICE_LISTS_SUCCESS:
      return {
        ...state,
        loading: false,
        priceLists: payload.data,
        pagination: payload.pagination,
      };
    case actions.GET_PRICE_LISTS_ERROR:
      return {
        ...state,
        loading: false,
        priceLists: initialState.priceLists,
        pagination: initialState.pagination,
      };
    case actions.PRICE_LISTS_REFRESH:
      return {
        ...state,
        priceLists: payload.priceList,
      };
    case actions.PRICE_LISTS_UI_REFRESH:
      return {
        ...state,
        priceListsUI: { ...state.priceListsUI, ...payload },
      };
    case actions.ADD_PRICE_LIST_MODAL_SHOW:
      return {
        ...state,
        isAddPriceListModalShow: true,
      };
    case actions.ADD_PRICE_LIST_MODAL_HIDE:
      return {
        ...state,
        isAddPriceListModalShow: false,
      };
    case actions.CURRENT_PRICE_LIST_REFRESH:
      return {
        ...state,
        currentPriceList: payload.priceList,
      };
    default:
      return { ...state };
  }
};
