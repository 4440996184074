import { all, call, put, takeEvery } from "redux-saga/effects";
import { addMinutes, format } from "date-fns";
import toast from "react-hot-toast";
import { push } from "connected-react-router";

import { orderBy } from "../../lib/lodash";
import { getItemFields } from "../../helpers/get-item-fields";
import { authActions as actions } from "./actions";
import { FORMATS } from "../../constants/formats";
import { AuthService } from "../../services/AuthService";
import { VatCodesService } from "../../services/VatCodesService";
import { UsersService } from "../../services/UsersService";
import { LabsService } from "../../services/LabsService";

import config from "../../config";

function* loginUser({ payload }) {
  try {
    const response = yield call(AuthService.userSignIn, payload.formData);
    const authUser = yield response;

    if (authUser?.data) {
      localStorage.setItem(
        "user_token_lab_frontend",
        authUser.data.access_token
      );
      localStorage.setItem(
        "expired_time",
        format(addMinutes(new Date(), 120), FORMATS.dateTimeLocalSeconds)
      );
      const patient = yield call(UsersService.getUser);
      yield put(actions.loginUserSuccess(patient.data.data));
      const labs = yield call(LabsService.getLabs);
      yield put(actions.getLabsSuccess(labs.data.data));
      yield put(push("/app/dashboard/orders"));
    } else {
      yield put(actions.loginUserError());
      toast.error(
        authUser.response ||
          authUser ||
          "Unknown error or unauthorized access attempt"
      );
    }
  } catch (error) {
    yield put(actions.loginUserError(error));
    toast.error("Please try to log in again.");
  }
}

function* getCurrentUser() {
  try {
    const patient = yield call(UsersService.getUser);
    if (patient.data) {
      yield put(actions.loginUserSuccess(patient.data.data));
    } else {
      yield put(actions.loginUserError());
    }
  } catch (error) {
    yield put(actions.loginUserError(error));
  }
}

function* getCurrentLabs() {
  try {
    const labs = yield call(LabsService.getLabs);
    if (labs.data) {
      yield put(actions.getLabsSuccess(labs.data.data));
    } else {
      yield put(actions.getLabsError());
    }
  } catch (error) {
    yield put(actions.getLabsError());
  }
}

function* getCurrentLabById({ payload: id }) {
  try {
    const lab = yield call(LabsService.getCurrentLab, id);
    if (lab.data) {
      const includedItems = lab.data.included?.reduce((accum, item) => {
        accum[item.type] = item;
        return accum;
      }, {});

      yield put(
        actions.getLabByIdSuccess({
          lab: lab.data.data,
          currency: includedItems.currency,
          country: includedItems.country,
          supplier: includedItems.supplier,
        })
      );
      if (includedItems?.country?.id) {
        yield put(actions.getVatCodes(includedItems.country.id));
      }
    } else {
      yield put(actions.getLabByIdError());
    }
  } catch (error) {
    yield put(actions.getLabByIdError());
  }
}

function* loginMobileUser({
  payload: { slotsId, clinicId, selectedSlot, pathName },
}) {
  try {
    const token = yield call(AuthService.getAutoStartToken);
    if (token.data) {
      const params = { autostarttoken: token.data.data.id };
      if (slotsId) {
        params.caregiver_id = slotsId;
      }
      if (clinicId) {
        params.clinic_id = clinicId;
      }
      if (selectedSlot?.attributes?.dtend) {
        params.dtend = encodeURIComponent(selectedSlot.attributes.dtend);
      }
      if (selectedSlot?.attributes?.dtstart) {
        params.dtstart = encodeURIComponent(selectedSlot.attributes.dtstart);
      }
      const redirectUrl = encodeURIComponent(
        `${config.url}${pathName}?${Object.keys(params)
          .map((key) => `${key}=${params[key]}`)
          .join("&")}`
      );
      window.location.href = `https://app.bankid.com/?autostarttoken=${params.autostarttoken}&redirect=${redirectUrl}`;
      yield put(actions.loginMobileUserSuccess());
    } else {
      yield put(actions.loginMobileUserError());
    }
  } catch (error) {
    yield put(actions.loginMobileUserError());
    toast.error("Please try to log in again.");
  }
}

function* signInUserQrCode({ payload: formData }) {
  try {
    const autoStartTokenResult = yield call(AuthService.getAutoStartToken);
    if (!autoStartTokenResult) {
      return;
    }

    const {
      data: { relationships, ...rest },
      included,
    } = autoStartTokenResult.data;
    const results = getItemFields(relationships, included);
    const autoStartToken = { ...results, ...rest };
    yield put(actions.autoStartTokenRefresh(autoStartToken));

    formData.append("autostarttoken", autoStartToken.id);

    yield put(actions.loginUser(formData));
  } catch (error) {
    yield put(actions.userSignInQrCodeError(error));
  }
}

function* getVatCodes({ payload }) {
  const response = yield call(VatCodesService.getCodes, payload);
  if (!response?.data?.data) {
    return;
  }

  const sortData = orderBy(
    response.data.data,
    (item) => item?.attributes?.vat_percentage,
    ["asc"]
  );

  yield put(actions.vatCodesRefresh(sortData));
}

export default function* authSaga() {
  yield all([
    takeEvery(actions.LOGIN_USER, loginUser),
    takeEvery(actions.LOGIN_MOBILE_USER, loginMobileUser),
    takeEvery(actions.GET_LABS, getCurrentLabs),
    takeEvery(actions.GET_LAB_BY_ID, getCurrentLabById),
    takeEvery(actions.GET_CURRENT_USER, getCurrentUser),
    takeEvery(actions.USER_SIGN_IN_QR_CODE, signInUserQrCode),
    takeEvery(actions.GET_VAT_CODES, getVatCodes),
  ]);
}
