import React, { Component } from "react";
import { connect } from "react-redux";

import { name, version } from "../../../../package.json";
import { formatDate } from "../../../helpers/format-date";
import { sendErrorReport } from "../../../redux/errorReporting/actions";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    const { sendReport } = this.props;

    sendReport({
      date: formatDate(new Date()),
      message: error.message,
      name: error.name,
      stack: errorInfo.componentStack,
      url: document.location.href,
      version: `${name}: ${version}`,
    });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <p style={{ marginTop: 40, marginLeft: 40 }}>Something went wrong.</p>
      );
    }
    return children;
  }
}

const mapDispatchToProps = (dispatch) => {
  return { sendReport: (data) => dispatch(sendErrorReport(data)) };
};

export default connect(() => ({}), mapDispatchToProps)(ErrorBoundary);
