import { createSelector } from "reselect";

const priceLists = ({ priceLists }) => priceLists;
const priceListsUI = ({ priceLists }) => priceLists.priceListsUI;
const currentPriceList = ({ priceLists }) => priceLists.currentPriceList;

export const selectPriceLists = createSelector(
  [priceLists],
  (priceLists) => priceLists
);

export const selectPriceListsUI = createSelector(
  [priceListsUI],
  (priceListsUI) => priceListsUI
);

export const selectCurrentPriceList = createSelector(
  [currentPriceList],
  (currentPriceList) => currentPriceList
);
