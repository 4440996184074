import React from "react";

const UserIcon = ({ className }) => (
  <svg
    style={{ fill: "#49c4a1" }}
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    width='20px'
    height='20px'
    className={className || ""}
  >
    <path d='M175 171.173c38.914 0 70.463-38.318 70.463-85.586C245.463 38.318 235.105 0 175 0s-70.465 38.318-70.465 85.587c0 47.268 31.549 85.586 70.465 85.586zM41.909 301.853c-.012-2.882-.024-.812 0 0zm266.176 2.251c.038-.789.013-5.474 0 0z' />
    <path d='M307.935 298.397c-1.305-82.342-12.059-105.805-94.352-120.657 0 0-11.584 14.761-38.584 14.761s-38.586-14.761-38.586-14.761C55.018 192.43 43.61 215.545 42.11 295.722c-.123 6.547-.18 6.891-.202 6.131.005 1.424.011 4.058.011 8.651 0 0 19.592 39.496 133.08 39.496 113.486 0 133.08-39.496 133.08-39.496 0-2.951.002-5.003.005-6.399-.022.47-.066-.441-.149-5.708z' />
  </svg>
);

export default UserIcon;
