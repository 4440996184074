export const downloadFile = async (
  fileName,
  data,
  mimeType = "application/pdf"
) => {
  const blob = new Blob([data], { type: mimeType });

  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");

  link.href = url;
  link.target = "_blank";
  link.download = fileName;
  link.style.display = "none";
  document.body.appendChild(link);

  link.click();
  document.body.removeChild(link);
};

export const printFile = (data, mimeType = "application/pdf") => {
  const urlCreator = window.URL || window.webkitURL;

  const blob = new Blob([data], { type: mimeType });
  const url = urlCreator.createObjectURL(blob);
  const win = window.open(url, "_blank");
  if (win) {
    win.focus();
    win.print();
  }
};

export const getNearestFixedValue = (arr, value) => {
  try {
    const numberValue = Number(value);
    return arr.reduce((prev, curr) => {
      return Math.abs(curr - numberValue) < Math.abs(prev - numberValue)
        ? curr
        : prev;
    });
  } catch (error) {
    return arr[0];
  }
};
