import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { format, addDays } from "date-fns";
import toast from "react-hot-toast";
import { push } from "connected-react-router";

import { NOTIFICATIONS } from "../../constants/notifications";
import { SupplierInvoicesService } from "../../services/SupplierInvoicesService";
import { cloneDeep } from "../../lib/lodash";
import { getItemFields } from "../../helpers/get-item-fields";
import { OrderLinesService } from "../../services/OrderLinesService";
import { orderItemsActions as actions } from "./actions";
import { selectCheckedOrderItemsId, selectOrderItemsIds } from "./selectors";
import { selectAuthUser } from "../auth/selectors";

function* getOrderItems({ payload }) {
  try {
    const response = yield call(OrderLinesService.getOrderItems, payload);
    if (response.data) {
      const { data, meta = {}, included } = response.data;
      const results = data.map((item) => {
        const { relationships, ...rest } = item;
        const result = getItemFields(relationships, included);
        return { ...result, ...rest };
      });
      yield put(
        actions.getOrderItemsSuccess({
          data: results,
          pagination: meta.pagination || {},
        })
      );
    }
  } catch (error) {
    yield put(actions.loadingError());
  }
}

function* orderItemCheckChange(action) {
  const { id, checked } = action.payload;

  const checkedOrderItems = yield select(selectCheckedOrderItemsId);
  const resOrderItems = cloneDeep(checkedOrderItems);

  if (checked && !resOrderItems.includes(id)) {
    resOrderItems.push(id);
  }
  if (!checked && resOrderItems.includes(id)) {
    resOrderItems.splice(resOrderItems.indexOf(id), 1);
  }

  yield put(actions.checkedOrderItemsRefresh(resOrderItems));
}

function* allOrderItemsCheckChange(action) {
  const checked = action.payload;

  const orderItemsIds = yield select(selectOrderItemsIds);
  const checkedOrderItemsResult = checked ? orderItemsIds : [];

  yield put(actions.checkedOrderItemsRefresh(checkedOrderItemsResult));
}

function* invoiceSave() {
  const checkedOrderItemsId = yield select(selectCheckedOrderItemsId);

  if (!checkedOrderItemsId.length) {
    yield put(actions.loadingError());
    toast.error(NOTIFICATIONS.selectOrderItem);
    return;
  }

  const { lab, currency } = yield select(selectAuthUser);
  const dueDate = addDays(new Date(), 30);

  const params = {
    dental_lab_id: lab?.id,
    currency_id: currency?.id,
    dental_lab_order_line_ids: checkedOrderItemsId.length
      ? checkedOrderItemsId.join(",")
      : null,
    invoice_date: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
    due_date: format(dueDate, "yyyy-MM-dd HH:mm:ss"),
  };

  const response = yield call(SupplierInvoicesService.saveInvoice, params);
  if (!response?.data) {
    yield put(actions.loadingError());
    return;
  }

  yield put(actions.loadingSuccess());
  yield put(actions.checkedOrderItemsRefresh([]));
  yield put(push(`/app/dashboard/invoices/${response.data.data.id}`));
}

export default function* userSaga() {
  yield all([takeEvery(actions.GET_ORDER_ITEMS, getOrderItems)]);
  yield all([takeEvery(actions.ORDER_ITEM_CHECK_CHANGE, orderItemCheckChange)]);
  yield all([
    takeEvery(actions.ALL_ORDER_ITEMS_CHECK_CHANGE, allOrderItemsCheckChange),
  ]);
  yield all([takeEvery(actions.INVOICE_SAVE, invoiceSave)]);
}
