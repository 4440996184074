import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import { Nav, NavItem } from "reactstrap";
import classnames from "classnames";

import { setContainerClassnames } from "../../redux/menu/actions";
import {
  OrdersIcon,
  DeliveryIcon,
  InvoiceIcon,
  OrderItemsIcon,
} from "../icons";
import packageVersion from "../../../package.json";

const Sidebar = ({ location }) => {
  const { containerClassnames } = useSelector((state) => state.menu);
  const [isFirstResizeFired, setIsFirstResizeFired] = useState(false);

  const dispatch = useDispatch();

  const menuItems = [
    {
      id: "dashboards",
      path: "dashboard/orders",
      label: "Orders",
      to: "/app/dashboard/orders",
      svgIcon: <OrdersIcon />,
    },
    {
      id: "deliveries",
      path: "dashboard/deliveries",
      label: "Deliveries",
      to: "/app/dashboard/deliveries",
      svgIcon: <DeliveryIcon />,
    },
    {
      id: "order-items",
      path: "dashboard/order-items",
      label: "Order items",
      to: "/app/dashboard/order-items",
      svgIcon: <OrderItemsIcon />,
    },
    {
      id: "invoices",
      path: "dashboard/invoices",
      label: "Invoices",
      to: "/app/dashboard/invoices",
      svgIcon: <InvoiceIcon />,
    },
  ];

  const getMenuClassesForResize = useCallback((classes) => {
    let nextClasses = classes.split(" ").filter((x) => x !== "");
    const windowWidth = window.innerWidth;
    if (windowWidth < 768) {
      nextClasses.push("menu-mobile");
    } else if (windowWidth < 1440) {
      nextClasses = nextClasses.filter((x) => x !== "menu-mobile");
      if (
        nextClasses.includes("menu-default") &&
        !nextClasses.includes("menu-sub-hidden")
      ) {
        nextClasses.push("menu-sub-hidden");
      }
    } else {
      nextClasses = nextClasses.filter((x) => x !== "menu-mobile");
      if (
        nextClasses.includes("menu-default") &&
        nextClasses.includes("menu-sub-hidden")
      ) {
        nextClasses = nextClasses.filter((x) => x !== "menu-sub-hidden");
      }
    }
    return nextClasses;
  }, []);

  const handleWindowResize = useCallback(
    (event) => {
      if (event && !event.isTrusted) {
        return;
      }

      const nextClasses = getMenuClassesForResize(containerClassnames);
      dispatch(setContainerClassnames(nextClasses.join(" ")));
    },
    [containerClassnames, dispatch, getMenuClassesForResize]
  );

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [handleWindowResize]);

  useEffect(() => {
    if (!isFirstResizeFired) {
      setIsFirstResizeFired(true);
      handleWindowResize();
    }
  }, [handleWindowResize, isFirstResizeFired]);

  return (
    <div className='sidebar' id='rootsidebat'>
      <div className='main-menu'>
        <div className='scroll'>
          <Nav vertical className='list-unstyled'>
            {menuItems?.map((item) => (
              <NavItem
                key={item.id}
                className={classnames({
                  active: location?.pathname?.includes(item.path),
                })}
              >
                {item.newWindow ? (
                  <a href={item.to} rel='noopener noreferrer' target='_blank'>
                    {item.svgIcon} <span> {item.label} </span>
                  </a>
                ) : (
                  <NavLink to={item.to} data-flag={item.id}>
                    {item.svgIcon} <span> {item.label} </span>
                  </NavLink>
                )}
              </NavItem>
            ))}
          </Nav>
        </div>
        <div className='version-container'>v {packageVersion.version}</div>
      </div>
    </div>
  );
};

export default withRouter(Sidebar);
