import { BaseService } from "../BaseService";
import { API_ROUTES } from "../../constants/routes";

class OrdersService extends BaseService {
  constructor(url) {
    super(url);
    this.getOrders = this.getOrders.bind(this);
    this.getOrderById = this.getOrderById.bind(this);
    this.getOrderFiles = this.getOrderFiles.bind(this);
    this.closeOrderById = this.closeOrderById.bind(this);
    this.acceptOrderById = this.acceptOrderById.bind(this);
  }

  async getOrders({ perPage, sortBy, labId, toDate, fromDate, ...rest }) {
    try {
      const response = await this.agent.get("", {
        params: {
          include:
            "clinic,dental_lab,dental_lab_constructions,dental_lab_accepter,dental_lab_order_lines,dental_lab_deliveries,dental_lab_order_lines.dental_lab_supplier_invoice_row.supplier_invoice,dental_lab_order_lines.dental_lab_delivery",
          order: "desc",
          per_page: perPage,
          sort_by: sortBy,
          dental_lab_id: labId,
          due_date_from: fromDate,
          due_date_to: toDate,
          ...rest,
        },
      });

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      throw error;
    }
  }

  async getOrderById(id) {
    const response = await this.agent.get(`/${id}`, {
      params: {
        include:
          "clinic,dental_lab,dental_lab,dental_lab_constructions.anchoring,dental_lab_constructions.color.scale,dental_lab_constructions.design,dental_lab_constructions.elements,dental_lab_constructions.interproximal_contact,dental_lab_constructions.material,dental_lab_constructions.occlusion,dental_lab_constructions.pontic_design,dental_lab_constructions.process_step,dental_lab_constructions.type,dental_lab_order_responses,dental_lab_order_responses.clinic_signer,dental_lab_order_responses.dental_lab_signer,dental_lab_order_responses.dental_lab_user,dental_lab_order_responses.files,files,patient",
      },
    });

    return Promise.resolve(response);
  }

  async getOrderFiles(id) {
    const response = await this.agent.get(`/${id}`, {
      params: {
        include: "files",
      },
    });

    return Promise.resolve(response);
  }

  async closeOrderById(orderId) {
    try {
      const response = await this.agent.post(`/${orderId}/close`);

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      return null;
    }
  }

  async acceptOrderById(orderId) {
    try {
      const response = await this.agent.post(`/${orderId}/accept`);

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      return null;
    }
  }
}

const ServiceInstance = new OrdersService(API_ROUTES.labDentalLabOrders);

export default ServiceInstance;
export { ServiceInstance as OrdersService };
