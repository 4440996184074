export const getInvoiceTotalPrice = (items) =>
  items.reduce((totalPrice, item) => {
    const {
      price_excluding_vat_per_quantity: price = 0,
      quantity = 1,
      discount_excluding_vat: discountExcludingVat = 0,
      vat_percentage: vatPercentage = 0,
    } = item.attributes || {};

    const total = price * quantity * (1 - discountExcludingVat);
    const vatAmount = total * vatPercentage;
    const priceIncludeVat = total + vatAmount;
    return totalPrice + priceIncludeVat;
  }, 0);
