import React from "react";
import toast from "react-hot-toast";

import styles from "./styles.module.scss";

const ToasterMessage = ({ id, message }) => (
  <div className={styles.root} onClick={() => toast.dismiss(id)}>
    {message}
  </div>
);

export default ToasterMessage;
