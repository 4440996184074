import { BaseService } from "../BaseService";
import { API_ROUTES } from "../../constants/routes";

class VatCodesService extends BaseService {
  constructor(url) {
    super(url);
    this.getCodes = this.getCodes.bind(this);
  }

  async getCodes(countryId) {
    try {
      const response = await this.agent.get("", {
        params: {
          country_id: countryId,
        },
      });
      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
    }
  }
}

const ServiceInstance = new VatCodesService(API_ROUTES.vatCodes);

export default ServiceInstance;
export { ServiceInstance as VatCodesService };
