import { userActions } from "./actions";

const initialState = {
  user: {},
  usersUI: {
    perPage: 10,
    page: 1,
  },
  loading: false,
  labDentalLabUserList: [],
  usersPagination: {},
};

export const userReducer = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case userActions.GET_USER:
    case userActions.GET_LAB_DENTAL_LAB_USERS:
      return {
        ...state,
        loading: true,
      };
    case userActions.GET_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: payload,
      };
    case userActions.GET_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case userActions.USERS_UI_REFRESH:
      return {
        ...state,
        usersUI: { ...state.usersUI, ...payload },
      };
    case userActions.GET_LAB_DENTAL_LAB_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        labDentalLabUserList: payload.data,
        usersPagination: payload.pagination,
      };
    case userActions.GET_LAB_DENTAL_LAB_USERS_ERROR:
      return {
        ...state,
        loading: false,
      };
    default:
      return { ...state };
  }
};
