import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";

import { store, history } from "./redux/store";
import { EventProvider } from "./lib/event-bus";
import ErrorBoundary from "./components/common/error-boundary";

import "react-confirm-alert/src/react-confirm-alert.css";
import "./assets/css/vendor/bootstrap.min.css";
import "./assets/css/sass/gogo.mainTheme.scss";
import "./index.css";

import App from "./app";

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ErrorBoundary>
        <EventProvider>
          <Suspense fallback={<div className='loading' />}>
            <App />
          </Suspense>
        </EventProvider>
      </ErrorBoundary>
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
);
