import { BaseService } from "../BaseService";
import { API_ROUTES } from "../../constants/routes";

class DeliveriesService extends BaseService {
  constructor(url) {
    super(url);
    this.getDelivery = this.getDelivery.bind(this);
    this.getDeliveries = this.getDeliveries.bind(this);
    this.saveDelivery = this.saveDelivery.bind(this);
    this.getPdf = this.getPdf.bind(this);
  }

  async getDelivery(id) {
    try {
      const response = await this.agent.get("", {
        params: {
          dental_lab_order_id: id,
          include:
            "dental_lab_order_lines.vat_code,dental_lab_order_lines.dental_lab_supplier_invoice_row.supplier_invoice,dental_lab_order.clinic",
        },
      });
      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      return null;
    }
  }

  async getDeliveries(params) {
    try {
      const response = await this.agent.get("", {
        params: {
          ...params,
          sort_by: "dispatched_at",
          order: "desc",
          include:
            "dental_lab_order,dental_lab_order_lines.vat_code,dental_lab_order_lines.dental_lab_supplier_invoice_row.supplier_invoice,dental_lab_order.clinic",
        },
      });
      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      return null;
    }
  }

  async saveDelivery(data) {
    try {
      const response = await this.agent.post("", data);
      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      return null;
    }
  }

  async getPdf(id) {
    try {
      const response = await this.agent.get(`/${id}/pdf`);

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      return null;
    }
  }
}

const ServiceInstance = new DeliveriesService(
  API_ROUTES.labDentalLabDeliveries
);

export default ServiceInstance;
export { ServiceInstance as DeliveriesService };
