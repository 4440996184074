import {
  MENU_SET_CLASSNAMES,
  MENU_CLICK_MOBILE_MENU,
} from "../../constants/action-types";

const initialState = {
  containerClassnames: "menu-default",
};

export const menuReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case MENU_SET_CLASSNAMES:
    case MENU_CLICK_MOBILE_MENU:
      return {
        ...state,
        containerClassnames: action.payload.containerClassnames,
      };

    default:
      return { ...state };
  }
};
