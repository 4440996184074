import { BaseService } from "../BaseService";
import { API_ROUTES } from "../../constants/routes";

class PricesService extends BaseService {
  constructor(url) {
    super(url);
    this.getPrices = this.getPrices.bind(this);
    this.savePrice = this.savePrice.bind(this);
    this.deletePrice = this.deletePrice.bind(this);
    this.updatePrice = this.updatePrice.bind(this);
  }

  async getPrices(params) {
    try {
      const response = await this.agent.get("", {
        params: {
          ...params,
          sort_by: "price_code",
          include: "currency,vat_code",
          deleted: 0,
        },
      });
      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
    }
  }

  async savePrice(data, supplierId) {
    try {
      const response = await this.agent.post(
        "",
        { data },
        {
          params: {
            supplier_id: supplierId,
          },
        }
      );

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      return null;
    }
  }

  async deletePrice(priceId) {
    try {
      const response = await this.agent.patch(`/${priceId}`, null, {
        params: {
          deleted: 1,
        },
      });

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      return null;
    }
  }

  async updatePrice(priceId, supplierId, data) {
    try {
      const response = await this.agent.patch(
        `/${priceId}`,
        { data },
        {
          params: {
            supplier_id: supplierId,
          },
        }
      );

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      return null;
    }
  }
}

const ServiceInstance = new PricesService(API_ROUTES.labPrices);

export default ServiceInstance;
export { ServiceInstance as PricesService };
