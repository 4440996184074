import { BaseService } from "../BaseService";
import { API_ROUTES } from "../../constants/routes";

class OrderResponsesService extends BaseService {
  constructor(url) {
    super(url);
    this.addResponseToOrderById = this.addResponseToOrderById.bind(this);
    this.signResponse = this.signResponse.bind(this);
  }

  async addResponseToOrderById(data) {
    try {
      const response = await this.agent.post("", { data });

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      return null;
    }
  }

  async signResponse(id, password) {
    try {
      const response = await this.agent.post(
        `/${id}/sign`,
        {},
        {
          params: {
            signing_password: password,
          },
        }
      );

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      return null;
    }
  }
}

const ServiceInstance = new OrderResponsesService(
  API_ROUTES.labDentalLabOrderResponses
);

export default ServiceInstance;
export { ServiceInstance as OrderResponsesService };
