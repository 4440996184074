const TYPES = {
  DASHBOARD_GET_ORDERS: "DASHBOARD_GET_ORDERS",
  DASHBOARD_GET_ORDERS_SUCCESS: "DASHBOARD_GET_ORDERS_SUCCESS",
  DASHBOARD_GET_ORDER_BY_ID: "DASHBOARD_GET_ORDER_BY_ID",
  DASHBOARD_GET_ORDER_BY_ID_SUCCESS: "DASHBOARD_GET_ORDER_BY_ID_SUCCESS",
  DASHBOARD_ORDERS_UI_REFRESH: "DASHBOARD_ORDERS_UI_REFRESH",
  DASHBOARD_RESET_CURRENT_ORDERS: "DASHBOARD_RESET_CURRENT_ORDERS",
  DASHBOARD_LOAD_RESPONSE_FILE: "DASHBOARD_LOAD_RESPONSE_FILE",
  DASHBOARD_FILE_LOADING_IDS_REFRESH: "DASHBOARD_FILE_LOADING_IDS_REFRESH",
  DASHBOARD_ADD_AND_SIGN_ORDER_BY_ID: "DASHBOARD_ADD_AND_SIGN_ORDER_BY_ID",
  DASHBOARD_ADD_AND_SIGN_ORDER_BY_ID_ERROR:
    "DASHBOARD_ADD_AND_SIGN_ORDER_BY_ID_ERROR",
  DASHBOARD_ADD_AND_SIGN_ORDER_BY_ID_SUCCESS:
    "DASHBOARD_ADD_AND_SIGN_ORDER_BY_ID_SUCCESS",
  DASHBOARD_SHOW_SIGNING_MODAL: "DASHBOARD_SHOW_SIGNING_MODAL",
  DASHBOARD_HIDE_SIGNING_MODAL: "DASHBOARD_HIDE_SIGNING_MODAL",
  DASHBOARD_CLOSE_ORDER_BY_ID: "DASHBOARD_CLOSE_ORDER_BY_ID",
  DASHBOARD_CLOSE_ORDER_BY_ID_SUCCESS: "DASHBOARD_CLOSE_ORDER_BY_ID_SUCCESS",
  DASHBOARD_CLOSE_ORDER_BY_ID_ERROR: "DASHBOARD_CLOSE_ORDER_BY_ID_ERROR",
  DASHBOARD_ACCEPT_ORDER_BY_ID: "DASHBOARD_ACCEPT_ORDER_BY_ID",
  DASHBOARD_ACCEPT_ORDER_BY_ID_SUCCESS: "DASHBOARD_ACCEPT_ORDER_BY_ID_SUCCESS",
  DASHBOARD_ACCEPT_ORDER_BY_ID_ERROR: "DASHBOARD_ACCEPT_ORDER_BY_ID_ERROR",
  DASHBOARD_ITEMS_RELOAD: "DASHBOARD_ITEMS_RELOAD",
  DASHBOARD_ITEMS_REFRESH: "DASHBOARD_ITEMS_REFRESH",
  DASHBOARD_CHECKED_ITEMS_ID_REFRESH: "DASHBOARD_CHECKED_ITEMS_ID_REFRESH",
  DASHBOARD_ITEM_CHECK_CHANGE: "DASHBOARD_ITEM_CHECK_CHANGE",
  DASHBOARD_ALL_ITEM_CHECK_CHANGE: "DASHBOARD_ALL_ITEM_CHECK_CHANGE",
  DASHBOARD_CREATE_DELIVERY: "DASHBOARD_CREATE_DELIVERY",
  DASHBOARD_ADD_ITEM_MODAL_SHOW: "DASHBOARD_ADD_ITEM_MODAL_SHOW",
  DASHBOARD_ADD_ITEM_MODAL_HIDE: "DASHBOARD_ADD_ITEM_MODAL_HIDE",
  DASHBOARD_ITEM_SAVE: "DASHBOARD_ITEM_SAVE",
  DASHBOARD_ITEM_UPDATE: "DASHBOARD_ITEM_UPDATE",
  DASHBOARD_ITEM_DELETE: "DASHBOARD_ITEM_DELETE",
  DASHBOARD_CURRENT_ITEM_REFRESH: "DASHBOARD_CURRENT_ITEM_REFRESH",
  DASHBOARD_DELIVERIES_RELOAD: "DASHBOARD_DELIVERIES_RELOAD",
  DASHBOARD_DELIVERIES_REFRESH: "DASHBOARD_DELIVERIES_REFRESH",
  DASHBOARD_DELIVERY_PDF_DOWNLOAD: "DASHBOARD_DELIVERY_PDF_DOWNLOAD",
  DASHBOARD_DELIVERY_PRINT: "DASHBOARD_DELIVERY_PRINT",
  DASHBOARD_GET_SEARCH_PRICES: "DASHBOARD_GET_SEARCH_PRICES",
  DASHBOARD_GET_SEARCH_PRICES_SUCCESS: "DASHBOARD_GET_SEARCH_PRICES_SUCCESS",
  DASHBOARD_GET_SEARCH_PRICES_ERROR: "DASHBOARD_GET_SEARCH_PRICES_ERROR",
  DASHBOARD_PRICE_LIST_ID_REFRESH: "DASHBOARD_PRICE_LIST_ID_REFRESH",

  DASHBOARD_LOADING_SUCCESS: "DASHBOARD_LOADING_SUCCESS",
  DASHBOARD_LOADING_ERROR: "DASHBOARD_LOADING_ERROR",
};

export const dashboardActions = {
  ...TYPES,
  getOrders: (payload) => ({
    type: TYPES.DASHBOARD_GET_ORDERS,
    payload,
  }),
  getOrdersSuccess: (payload) => ({
    type: TYPES.DASHBOARD_GET_ORDERS_SUCCESS,
    payload,
  }),
  getOrderById: (payload) => ({
    type: TYPES.DASHBOARD_GET_ORDER_BY_ID,
    payload,
  }),
  getOrderByIdSuccess: (payload) => ({
    type: TYPES.DASHBOARD_GET_ORDER_BY_ID_SUCCESS,
    payload,
  }),
  ordersUIrefresh: (payload) => ({
    type: TYPES.DASHBOARD_ORDERS_UI_REFRESH,
    payload,
  }),
  resetCurrentOrder: () => ({
    type: TYPES.DASHBOARD_RESET_CURRENT_ORDERS,
  }),
  loadResponseFile: (payload) => ({
    type: TYPES.DASHBOARD_LOAD_RESPONSE_FILE,
    payload,
  }),
  fileLoadingIDsRefresh: (payload) => ({
    type: TYPES.DASHBOARD_FILE_LOADING_IDS_REFRESH,
    payload,
  }),
  addAndSignOrderById: (payload) => ({
    type: TYPES.DASHBOARD_ADD_AND_SIGN_ORDER_BY_ID,
    payload,
  }),
  addAndSignOrderByIdSuccess: () => ({
    type: TYPES.DASHBOARD_ADD_AND_SIGN_ORDER_BY_ID_SUCCESS,
  }),
  addAndSignOrderByIdError: () => ({
    type: TYPES.DASHBOARD_ADD_AND_SIGN_ORDER_BY_ID_ERROR,
  }),
  closeOrderById: (payload) => ({
    type: TYPES.DASHBOARD_CLOSE_ORDER_BY_ID,
    payload,
  }),
  closeOrderByIdSuccess: () => ({
    type: TYPES.DASHBOARD_CLOSE_ORDER_BY_ID_SUCCESS,
  }),
  closeOrderByIdError: (payload) => ({
    type: TYPES.DASHBOARD_CLOSE_ORDER_BY_ID_ERROR,
    payload,
  }),
  acceptOrderById: (payload) => ({
    type: TYPES.DASHBOARD_ACCEPT_ORDER_BY_ID,
    payload,
  }),
  acceptOrderByIdSuccess: () => ({
    type: TYPES.DASHBOARD_ACCEPT_ORDER_BY_ID_SUCCESS,
  }),
  acceptOrderByIdError: (payload) => ({
    type: TYPES.DASHBOARD_ACCEPT_ORDER_BY_ID_ERROR,
    payload,
  }),
  showSigningModal: () => ({
    type: TYPES.DASHBOARD_SHOW_SIGNING_MODAL,
  }),
  hideSigningModal: () => ({
    type: TYPES.DASHBOARD_HIDE_SIGNING_MODAL,
  }),
  showAddItemModal: () => ({
    type: TYPES.DASHBOARD_ADD_ITEM_MODAL_SHOW,
  }),
  hideAddItemModal: () => ({
    type: TYPES.DASHBOARD_ADD_ITEM_MODAL_HIDE,
  }),
  itemsReload: (id) => ({
    type: TYPES.DASHBOARD_ITEMS_RELOAD,
    payload: { id },
  }),
  itemsRefresh: (payload) => ({
    type: TYPES.DASHBOARD_ITEMS_REFRESH,
    payload,
  }),
  checkedItemsIdRefresh: (payload) => ({
    type: TYPES.DASHBOARD_CHECKED_ITEMS_ID_REFRESH,
    payload,
  }),
  itemCheckChange: (payload) => ({
    type: TYPES.DASHBOARD_ITEM_CHECK_CHANGE,
    payload,
  }),
  allItemsCheckChange: (payload) => ({
    type: TYPES.DASHBOARD_ALL_ITEM_CHECK_CHANGE,
    payload,
  }),
  createDelivery: (payload) => ({
    type: TYPES.DASHBOARD_CREATE_DELIVERY,
    payload,
  }),
  itemSave: (payload) => ({
    type: TYPES.DASHBOARD_ITEM_SAVE,
    payload,
  }),
  itemUpdate: (id, values) => ({
    type: TYPES.DASHBOARD_ITEM_UPDATE,
    payload: { id, values },
  }),
  itemDelete: (itemId) => ({
    type: TYPES.DASHBOARD_ITEM_DELETE,
    payload: { itemId },
  }),
  currentItemRefresh: (item) => ({
    type: TYPES.DASHBOARD_CURRENT_ITEM_REFRESH,
    payload: item,
  }),
  deliveriesReload: (id) => ({
    type: TYPES.DASHBOARD_DELIVERIES_RELOAD,
    payload: { id },
  }),
  deliveriesRefresh: (payload) => ({
    type: TYPES.DASHBOARD_DELIVERIES_REFRESH,
    payload,
  }),
  deliveryPdfDownload: (id) => ({
    type: TYPES.DASHBOARD_DELIVERY_PDF_DOWNLOAD,
    payload: { id },
  }),
  deliveryPrint: (id) => ({
    type: TYPES.DASHBOARD_DELIVERY_PRINT,
    payload: { id },
  }),
  getSearchPrices: (payload) => ({
    type: TYPES.DASHBOARD_GET_SEARCH_PRICES,
    payload,
  }),
  getSearchPricesSuccess: (payload) => ({
    type: TYPES.DASHBOARD_GET_SEARCH_PRICES_SUCCESS,
    payload,
  }),
  getSearchPricesError: () => ({
    type: TYPES.DASHBOARD_GET_SEARCH_PRICES_ERROR,
  }),
  priceListIdRefresh: (payload) => ({
    type: TYPES.DASHBOARD_PRICE_LIST_ID_REFRESH,
    payload,
  }),

  dashboardLoadingSuccess: () => ({
    type: TYPES.DASHBOARD_LOADING_SUCCESS,
  }),
  dashboardLoadingError: () => ({
    type: TYPES.DASHBOARD_LOADING_ERROR,
  }),
};
