import React from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

import Header from "../header";
import Sidebar from "../sidebar";

const AppLayout = ({ history, children }) => {
  const { containerClassnames } = useSelector((state) => state.menu);

  return (
    <div id='app-container' className={containerClassnames}>
      <Header history={history} />
      <Sidebar />
      <main>
        <div className='container-fluid'>{children}</div>
      </main>
    </div>
  );
};

export default withRouter(AppLayout);
