import {
  SEND_ERROR_REPORT,
  SEND_ERROR_REPORT_SUCCESS,
  SEND_ERROR_REPORT_ERROR,
} from "../../constants/action-types";

export const sendErrorReport = (data) => {
  return {
    type: SEND_ERROR_REPORT,
    payload: data,
  };
};

export const sendErrorReportSuccess = (res) => {
  return {
    type: SEND_ERROR_REPORT_SUCCESS,
    payload: res,
  };
};

export const sendErrorReportError = (error) => {
  return {
    type: SEND_ERROR_REPORT_ERROR,
    payload: error,
  };
};
