import React from "react";

const DeliveryIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 256 256'
    xmlSpace='preserve'
    width='16'
    height='16'
    {...props}
  >
    <g
      style={{
        stroke: "none",
        strokeWidth: 0,
        strokeDasharray: "none",
        strokeLinecap: "butt",
        strokeLinejoin: "miter",
        strokeMiterlimit: 10,
        fill: "none",
        fillRule: "nonzero",
        opacity: 1,
      }}
    >
      <path
        d='M89.034 43.825 74.452 27.423a3.827 3.827 0 0 0-2.859-1.284H58.256v-.448a6.76 6.76 0 0 0-6.751-6.752H6.752A6.761 6.761 0 0 0 0 25.692V60.79a4.028 4.028 0 0 0 4.023 4.024h10.374c.827 3.573 4.029 6.247 7.85 6.247s7.023-2.674 7.85-6.247H68.958c.827 3.573 4.029 6.247 7.85 6.247s7.023-2.674 7.85-6.247h1.519a3.829 3.829 0 0 0 3.825-3.825V46.367a3.839 3.839 0 0 0-.968-2.542zm-3.821.168H67.936a.61.61 0 0 1-.609-.61v-7.785a.61.61 0 0 1 .609-.609h9.272l8.005 9.004zM6.752 21.907h44.753a3.79 3.79 0 0 1 3.784 3.785v22.77H2.967v-22.77a3.79 3.79 0 0 1 3.785-3.785zm15.494 46.186c-2.81 0-5.097-2.286-5.097-5.097s2.287-5.097 5.097-5.097 5.097 2.286 5.097 5.097-2.286 5.097-5.097 5.097zm7.972-6.247c-.561-3.902-3.917-6.913-7.972-6.913s-7.411 3.011-7.972 6.913H4.023a1.058 1.058 0 0 1-1.056-1.057v-9.361h52.322v10.417H30.218zm46.589 6.247c-2.811 0-5.097-2.286-5.097-5.097s2.286-5.097 5.097-5.097 5.097 2.286 5.097 5.097-2.287 5.097-5.097 5.097zm9.368-6.247h-1.397c-.561-3.902-3.917-6.913-7.972-6.913s-7.411 3.011-7.972 6.913H58.256v-32.74h13.337a.86.86 0 0 1 .641.288l2.335 2.627h-6.634a3.58 3.58 0 0 0-3.576 3.576v7.785a3.58 3.58 0 0 0 3.576 3.577h19.097v14.029a.856.856 0 0 1-.857.858z'
        style={{
          stroke: "none",
          strokeWidth: 1,
          strokeDasharray: "none",
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeMiterlimit: 10,
          fill: "currentColor",
          fillRule: "nonzero",
          opacity: 1,
        }}
        transform='matrix(2.81 0 0 2.81 1.407 1.407)'
      />
    </g>
  </svg>
);

export default DeliveryIcon;
