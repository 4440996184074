const TYPES = {
  GET_USER: "GET_USER",
  GET_USER_SUCCESS: "GET_USER_SUCCESS",
  GET_USER_ERROR: "GET_USER_ERROR",
  GET_USERS_LIST: "GET_USERS_LIST",
  USERS_UI_REFRESH: "USERS_UI_REFRESH",
  GET_LAB_DENTAL_LAB_USERS: "GET_LAB_DENTAL_LAB_USERS",
  GET_LAB_DENTAL_LAB_USERS_SUCCESS: "GET_LAB_DENTAL_LAB_USERS_SUCCESS",
  GET_LAB_DENTAL_LAB_USERS_ERROR: "GET_LAB_DENTAL_LAB_USERS_ERROR",
};

export const userActions = {
  ...TYPES,
  getUser: () => ({
    type: TYPES.GET_USER,
  }),
  getUserSuccess: (payload) => ({
    type: TYPES.GET_USER_SUCCESS,
    payload,
  }),
  getUserError: (error) => ({
    type: TYPES.GET_USER_ERROR,
    payload: error,
  }),
  usersUIrefresh: (payload) => ({
    type: TYPES.USERS_UI_REFRESH,
    payload,
  }),
  getLabDentalLabUsers: (payload) => ({
    type: TYPES.GET_LAB_DENTAL_LAB_USERS,
    payload,
  }),
  getLabDentalLabUserSuccess: (payload) => ({
    type: TYPES.GET_LAB_DENTAL_LAB_USERS_SUCCESS,
    payload,
  }),
  getLabDentalLabUserError: () => ({
    type: TYPES.GET_LAB_DENTAL_LAB_USERS_ERROR,
  }),
};
