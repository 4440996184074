const TYPES = {
  GET_DELIVERIES: "GET_DELIVERIES",
  GET_DELIVERIES_SUCCESS: "GET_DELIVERIES_SUCCESS",
  GET_DELIVERIES_ERROR: "GET_DELIVERIES_ERROR",
  DELIVERIES_REFRESH: "DELIVERIES_REFRESH",

  DELIVERIES_UI_REFRESH: "DELIVERIES_UI_REFRESH",
  DELIVERIES_CHECK_CHANGE: "DELIVERIES_CHECK_CHANGE",
  CHECKED_DELIVERIES_REFRESH: "CHECKED_ORDER_ITEMS_REFRESH",
  ALL_DELIVERIES_CHECK_CHANGE: "ALL_DELIVERIES_CHECK_CHANGE",
  INVOICE_SAVE: "INVOICE_SAVE",

  LOADING_SUCCESS: "LOADING_SUCCESS",
  LOADING_ERROR: "LOADING_ERROR",
};

export const deliveriesActions = {
  ...TYPES,
  getDeliveries: (payload) => ({
    type: TYPES.GET_DELIVERIES,
    payload,
  }),
  getDeliveriesSuccess: (payload) => ({
    type: TYPES.GET_DELIVERIES_SUCCESS,
    payload,
  }),
  getDeliveriesError: () => ({
    type: TYPES.GET_DELIVERIES_ERROR,
  }),
  deliveriesRefresh: (deliveries) => ({
    type: TYPES.DELIVERIES_REFRESH,
    payload: { deliveries },
  }),
  deliveriesUIrefresh: (payload) => ({
    type: TYPES.DELIVERIES_UI_REFRESH,
    payload,
  }),
  deliveriesCheckChange: (id, checked) => ({
    type: TYPES.DELIVERIES_CHECK_CHANGE,
    payload: { id, checked },
  }),
  checkedDeliveriesRefresh: (payload) => ({
    type: TYPES.CHECKED_DELIVERIES_REFRESH,
    payload,
  }),
  allDeliveriesCheckChange: (payload) => ({
    type: TYPES.ALL_DELIVERIES_CHECK_CHANGE,
    payload,
  }),
  invoiceSave: () => ({
    type: TYPES.INVOICE_SAVE,
  }),
  loadingSuccess: () => ({
    type: TYPES.LOADING_SUCCESS,
  }),
  loadingError: () => ({
    type: TYPES.LOADING_ERROR,
  }),
};
