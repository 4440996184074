import { eventChannel } from "redux-saga";
import { take, put, call, fork, all } from "redux-saga/effects";

import { eventEmitter } from "../../lib/event-bus";
import { CUSTOM_EVENTS } from "../../constants/customEvents";
import { authActions } from "../auth/actions";

function createLogoutChannel() {
  return eventChannel((emit) => {
    const logoutHandler = () => {
      emit(authActions.logoutUser());
    };

    eventEmitter.on(CUSTOM_EVENTS.logout, logoutHandler);

    return () => {
      eventEmitter.off(CUSTOM_EVENTS.logout, logoutHandler);
    };
  });
}

function* logoutSaga() {
  const channel = yield call(createLogoutChannel);
  while (true) {
    const action = yield take(channel);

    yield put(action);
  }
}

export default function* socketsSaga() {
  yield all([fork(logoutSaga)]);
}
