import React, { Suspense, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Route, Switch, Redirect } from "react-router-dom";

import { authActions as actions } from "./redux/auth/actions";

import UserLayout from "./components/layouts/user-layout";
import AppLayout from "./components/layouts/app-layout";
import Loader from "./components/common/loader";
import ToasterContainer from "./components/common/toaster/toaster-container";

const OrderPage = React.lazy(() => import("./pages/order-details/container"));
const OrdersPage = React.lazy(() => import("./pages/orders-list/container"));
const DeliveriesPage = React.lazy(() => import("./pages/deliveries/container"));
const InvoicesPage = React.lazy(() =>
  import("./pages/invoices-list/container")
);
const InvoicePage = React.lazy(() =>
  import("./pages/invoice-details/container")
);
const ErrorPage = React.lazy(() => import("./pages/error"));
const LoginPage = React.lazy(() => import("./pages/login/container"));
const UsersPage = React.lazy(() => import("./pages/users/container"));
const OrderItemsPage = React.lazy(() =>
  import("./pages/order-items/container")
);
const PriceListsPage = React.lazy(() =>
  import("./pages/price-lists/container")
);
const PricesPage = React.lazy(() => import("./pages/prices/container"));

const AuthRoute = ({ component: Component, authUser, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("user_token_lab_frontend") ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/user/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
};

const AuthRedirect = () => (
  <Redirect
    to={
      localStorage.getItem("user_token_lab_frontend")
        ? "/app/dashboard/orders"
        : "/user/login"
    }
  />
);

const App = () => {
  const { labs } = useSelector((state) => state.authUser);
  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.getItem("user_token_lab_frontend") && labs?.length > 0) {
      dispatch(actions.getLabById(labs[0].id));
    }
  }, [labs, dispatch]);

  useEffect(() => {
    if (localStorage.getItem("user_token_lab_frontend")) {
      dispatch(actions.getCurrentUser());
      dispatch(actions.getLabs());
    }
  }, [dispatch]);

  return (
    <div className='h-100'>
      <ToasterContainer />
      <Suspense
        fallback={
          <div className='fallback'>
            <Loader />
          </div>
        }
      >
        <Switch>
          <Route
            path='/error'
            exact
            render={(props) => <ErrorPage {...props} />}
          />
          <Route
            path='/user/login'
            exact
            render={() => (
              <UserLayout>
                <LoginPage />
              </UserLayout>
            )}
          />
          <AppLayout>
            <Switch>
              <AuthRoute
                exact
                path='/app/dashboard/orders'
                component={OrdersPage}
              />
              <AuthRoute
                exact
                path='/app/dashboard/orders/:id'
                component={OrderPage}
              />
              <AuthRoute
                exact
                path='/app/dashboard/deliveries'
                component={DeliveriesPage}
              />
              <AuthRoute
                exact
                path='/app/dashboard/invoices'
                component={InvoicesPage}
              />
              <AuthRoute
                exact
                path='/app/dashboard/invoices/:id'
                component={InvoicePage}
              />
              <AuthRoute
                exact
                path='/app/dashboard/order-items'
                component={OrderItemsPage}
              />
              <AuthRoute path='/users' exact component={UsersPage} />
              <AuthRoute path='/price-lists' exact component={PriceListsPage} />
              <AuthRoute path='/price-lists/:id' exact component={PricesPage} />
              <Route path='/' exact render={AuthRedirect} />
              <Route path='/app' exact render={AuthRedirect} />
              <Route path='/app/dashboard' exact render={AuthRedirect} />
              <Redirect to='/error' />
            </Switch>
          </AppLayout>
        </Switch>
      </Suspense>
    </div>
  );
};

export default App;
