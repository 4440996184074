import { createSelector } from "reselect";

const orderItems = ({ orderItems }) => orderItems;
const orderItemsList = ({ orderItems }) => orderItems.orderItemsList;
const orderItemsUI = ({ orderItems }) => orderItems.orderItemsUI;
const checkedOrderItems = ({ orderItems }) => orderItems.checkedOrderItems;

export const selectOrderItems = createSelector(
  [orderItems],
  (orderItems) => orderItems
);

export const selectOrderItemsIds = createSelector(
  [orderItemsList],
  (orderItemsList) => orderItemsList.map((item) => item.id)
);

export const selectOrderItemsUI = createSelector(
  [orderItemsUI],
  (orderItemsUI) => orderItemsUI
);

export const selectCheckedOrderItemsId = createSelector(
  [checkedOrderItems],
  (checkedOrderItems) => checkedOrderItems
);

export const selectCheckedOrderItemsIdLength = createSelector(
  [selectCheckedOrderItemsId],
  (selectCheckedOrderItemsId) => selectCheckedOrderItemsId.length
);
