import { all, call, put, takeLatest, select } from "redux-saga/effects";

import { SupplierInvoicesService } from "../../services/SupplierInvoicesService";
import { invoicesActions as actions } from "./actions";

import { selectLabId } from "../auth/selectors";
import { getItemFields } from "../../helpers/get-item-fields";

import { getInvoiceTotalPrice } from "../../helpers/invoices";

function* invoicesReload({ payload }) {
  try {
    const response = yield call(SupplierInvoicesService.getInvoices, payload);
    yield put(actions.loadingSuccess());

    const result = response.data.data.map((item) => {
      const { relationships, ...rest } = item;
      const result = getItemFields(relationships, response.data.included);
      const total = getInvoiceTotalPrice(result.supplier_invoice_rows);
      return { ...rest, total };
    });
    if (response?.data?.data) {
      yield put(actions.invoicesRefresh(result, response.data.meta.pagination));
    }
  } catch (error) {
    yield put(actions.loadingError());
  }
}

function* currentInvoiceReload({ payload }) {
  const { invoiceId } = payload;
  const labId = yield select(selectLabId);

  try {
    const response = yield call(
      SupplierInvoicesService.getCurrentInvoice,
      invoiceId,
      labId
    );
    if (!response?.data) {
      return;
    }

    const { relationships, ...rest } = response.data.data;
    const result = getItemFields(relationships, response.data.included);

    yield put(actions.currentInvoiceRefresh({ ...result, ...rest }));
    yield put(actions.loadingSuccess());
  } catch (error) {
    yield put(actions.loadingError());
  }
}

export default function* invoicesSaga() {
  yield all([
    takeLatest(actions.INVOICES_RELOAD, invoicesReload),
    takeLatest(actions.CURRENT_INVOICE_RELOAD, currentInvoiceReload),
  ]);
}
