import { menuReducer } from "./menu/reducer";
import { authReducer } from "./auth/reducer";
import { dashboardReducer } from "./dashboard/reducer";
import { deliveriesReducer } from "./deliveries/reducer";
import { errorReportingReducer } from "./errorReporting/reducer";
import { invoicesReducer } from "./invoices/reducer";
import { userReducer } from "./users/reducer";
import { orderItemsReducer } from "./orderItems/reducer";
import { priceListsReducer } from "./priceLists/reducer";
import { pricesReducer } from "./prices/reducer";

const rootReducer = {
  menu: menuReducer,
  authUser: authReducer,
  dashboard: dashboardReducer,
  deliveries: deliveriesReducer,
  orderItems: orderItemsReducer,
  errorReporting: errorReportingReducer,
  invoices: invoicesReducer,
  user: userReducer,
  priceLists: priceListsReducer,
  prices: pricesReducer,
};

export default rootReducer;
