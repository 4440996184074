import React from "react";

const UnorderedListIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    style={{ width: "16px", height: "16px", marginBottom: "4px" }}
    viewBox='2 2 20 20'
    {...props}
  >
    <g id='SVGRepo_bgCarrier' strokeWidth='0' />
    <g
      id='SVGRepo_tracerCarrier'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <g id='SVGRepo_iconCarrier'>
      <path
        fillRule='evenodd'
        d='M4 7a1 1 0 100-2 1 1 0 000 2zm4.75-1.5a.75.75 0 000 1.5h11.5a.75.75 0 000-1.5H8.75zm0 6a.75.75 0 000 1.5h11.5a.75.75 0 000-1.5H8.75zm0 6a.75.75 0 000 1.5h11.5a.75.75 0 000-1.5H8.75zM5 12a1 1 0 11-2 0 1 1 0 012 0zm-1 7a1 1 0 100-2 1 1 0 000 2z'
      />
    </g>
  </svg>
);

export default UnorderedListIcon;
