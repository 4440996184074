import React from "react";

const AttachFileIcon = (props) => (
  <svg
    viewBox='0 0 1024 1024'
    xmlns='http://www.w3.org/2000/svg'
    style={{ width: "20px", height: "20px" }}
    {...props}
  >
    <path d='M172.72 1007.632c-43.408 0-85.085-17.965-118.301-51.213-73.648-73.888-73.648-194.063-.017-267.903L628.674 78.692c89.6-89.744 226.848-81.68 327.008 18.608 44.88 44.96 70.064 109.776 69.12 177.904-.945 67.409-27.28 131.92-72.289 177.008L518.497 914.26c-12.08 12.945-32.336 13.536-45.231 1.393-12.864-12.16-13.488-32.448-1.36-45.345l434.672-462.752c34-34.064 53.504-82.385 54.223-133.249.72-50.895-17.664-98.88-50.368-131.664-61.44-61.568-161.473-93.808-235.841-19.264L100.336 733.203c-49.376 49.503-49.36 129.008-.64 177.855 22.847 22.864 49.967 34 78.847 32.255 28.576-1.744 57.952-16.4 82.72-41.232L718.19 415.745c16.56-16.592 49.84-57.264 15.968-91.216-19.184-19.216-32.656-18.032-37.087-17.664-12.656 1.12-27.44 9.872-42.784 25.264l-343.92 365.776c-12.144 12.912-32.416 13.536-45.233 1.36-12.88-12.128-13.472-32.448-1.36-45.312L608.32 287.489c27.088-27.216 54.784-41.968 82.976-44.496 22-1.953 54.72 2.736 88.096 36.208 49.536 49.631 43.376 122.432-15.28 181.216L307.184 946.72c-36.48 36.608-80.529 57.872-124.721 60.591-3.248.224-6.496.32-9.744.32z' />
  </svg>
);
export default AttachFileIcon;
