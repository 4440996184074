const TYPES = {
  GET_PRICES: "GET_PRICES",
  GET_PRICES_SUCCESS: "GET_PRICES_SUCCESS",
  GET_PRICES_ERROR: "GET_PRICES_ERROR",
  ADD_PRICE_MODAL_SHOW: "ADD_PRICE_MODAL_SHOW",
  ADD_PRICE_MODAL_HIDE: "ADD_PRICE_MODAL_HIDE",
  PRICES_REFRESH: "PRICES_REFRESH",
  PRICE_SAVE: "PRICE_SAVE",
  PRICE_DELETE: "PRICE_DELETE",
  PRICE_UPDATE: "PRICE_UPDATE",
  CURRENT_PRICE_REFRESH: "CURRENT_PRICE_REFRESH",
};

export const pricesActions = {
  ...TYPES,
  getPrices: (payload) => ({
    type: TYPES.GET_PRICES,
    payload,
  }),
  getPricesSuccess: (payload) => ({
    type: TYPES.GET_PRICES_SUCCESS,
    payload,
  }),
  getPricesError: () => ({
    type: TYPES.GET_PRICES_ERROR,
  }),
  showAddPriceModal: () => ({
    type: TYPES.ADD_PRICE_MODAL_SHOW,
  }),
  hideAddPriceModal: () => ({
    type: TYPES.ADD_PRICE_MODAL_HIDE,
  }),
  pricesRefresh: (prices) => ({
    type: TYPES.PRICES_REFRESH,
    payload: { prices },
  }),
  priceSave: (payload) => ({
    type: TYPES.PRICE_SAVE,
    payload,
  }),
  priceDelete: (priceId, priceListId) => ({
    type: TYPES.PRICE_DELETE,
    payload: { priceId, priceListId },
  }),
  priceUpdate: (id, values, priceListId) => ({
    type: TYPES.PRICE_UPDATE,
    payload: { id, values, priceListId },
  }),
  currentPriceRefresh: (price) => ({
    type: TYPES.CURRENT_PRICE_REFRESH,
    payload: { price },
  }),
};
