const prefix = "[Deliveries]";

const TYPES = {
  GET_ORDER_ITEMS: `${prefix} GET_ORDER_ITEMS`,
  GET_ORDER_ITEMS_SUCCESS: `${prefix} GET_ORDER_ITEMS_SUCCESS`,
  ORDER_ITEM_CHECK_CHANGE: `${prefix} ORDER_ITEM_CHECK_CHANGE`,
  ORDER_ITEMS_REFRESH: `${prefix} ORDER_ITEMS_REFRESH`,
  ORDER_ITEMS_UI_REFRESH: "ORDER_ITEMS_UI_REFRESH",
  CHECKED_ORDER_ITEMS_REFRESH: `${prefix} CHECKED_ORDER_ITEMS_REFRESH`,
  INVOICE_SAVE: `${prefix} INVOICE_SAVE`,
  ALL_ORDER_ITEMS_CHECK_CHANGE: `${prefix} ALL_ORDER_ITEMS_CHECK_CHANGE`,

  LOADING_SUCCESS: `${prefix} LOADING_SUCCESS`,
  LOADING_ERROR: `${prefix} LOADING_ERROR`,
};

export const orderItemsActions = {
  ...TYPES,
  getOrderItems: (payload) => ({
    type: TYPES.GET_ORDER_ITEMS,
    payload,
  }),
  getOrderItemsSuccess: (payload) => ({
    type: TYPES.GET_ORDER_ITEMS_SUCCESS,
    payload,
  }),
  orderItemCheckChange: (id, checked) => ({
    type: TYPES.ORDER_ITEM_CHECK_CHANGE,
    payload: { id, checked },
  }),
  orderItemsRefresh: (payload) => ({
    type: TYPES.ORDER_ITEMS_REFRESH,
    payload,
  }),
  orderItemsUIrefresh: (payload) => ({
    type: TYPES.ORDER_ITEMS_UI_REFRESH,
    payload,
  }),
  checkedOrderItemsRefresh: (payload) => ({
    type: TYPES.CHECKED_ORDER_ITEMS_REFRESH,
    payload,
  }),
  invoiceSave: () => ({
    type: TYPES.INVOICE_SAVE,
  }),
  allOrderItemsCheckChange: (payload) => ({
    type: TYPES.ALL_ORDER_ITEMS_CHECK_CHANGE,
    payload,
  }),
  loadingSuccess: () => ({
    type: TYPES.LOADING_SUCCESS,
  }),
  loadingError: () => ({
    type: TYPES.LOADING_ERROR,
  }),
};
