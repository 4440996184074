const prefix = "[invoices]";

const TYPES = {
  INVOICES_RELOAD: `${prefix} INVOICES_RELOAD`,
  INVOICES_REFRESH: `${prefix} INVOICES_REFRESH`,
  INVOICES_UI_REFRESH: "INVOICES_UI_REFRESH",
  CURRENT_INVOICE_RELOAD: `${prefix} CURRENT_INVOICE_RELOAD`,
  CURRENT_INVOICE_REFRESH: `${prefix} CURRENT_INVOICE_REFRESH`,
  LOADING_SUCCESS: `${prefix} LOADING_SUCCESS`,
  LOADING_ERROR: `${prefix} LOADING_ERROR`,
};

export const invoicesActions = {
  ...TYPES,
  invoicesReload: (payload) => ({
    type: TYPES.INVOICES_RELOAD,
    payload,
  }),
  invoicesRefresh: (data, pagination) => ({
    type: TYPES.INVOICES_REFRESH,
    payload: { data, pagination },
  }),
  invoicesUIrefresh: (payload) => ({
    type: TYPES.INVOICES_UI_REFRESH,
    payload,
  }),
  currentInvoiceReload: (invoiceId) => ({
    type: TYPES.CURRENT_INVOICE_RELOAD,
    payload: { invoiceId },
  }),
  currentInvoiceRefresh: (payload) => ({
    type: TYPES.CURRENT_INVOICE_REFRESH,
    payload,
  }),
  loadingSuccess: () => ({
    type: TYPES.LOADING_SUCCESS,
  }),
  loadingError: () => ({
    type: TYPES.LOADING_ERROR,
  }),
};
