import { all, call, put, takeLatest, select } from "redux-saga/effects";

import { PricesService } from "../../services/PricesService";
import { pricesActions } from "./actions";
import { getItemFields } from "../../helpers/get-item-fields";
import { createPriceData } from "../../helpers/request-creators";
import { selectPrices } from "./selectors";
import {
  selectLabId,
  selectSupplierId,
  selectCurrencyId,
} from "../auth/selectors";

function* getPrices({ payload }) {
  try {
    const response = yield call(PricesService.getPrices, payload);
    if (response.data) {
      const results = response.data.data.map((item) => {
        const { relationships, ...rest } = item;
        const result = getItemFields(relationships, response.data.included);
        return { ...result, ...rest };
      });

      yield put(
        pricesActions.getPricesSuccess({
          data: results,
          pagination: response.data.meta?.pagination || {},
        })
      );
    }
  } catch (error) {
    yield put(pricesActions.getPricesError());
  }
}

function* priceSave({ payload }) {
  const { priceListId } = payload;

  const labId = yield select(selectLabId);
  const supplierId = yield select(selectSupplierId);
  const currencyId = yield select(selectCurrencyId);
  const data = createPriceData({ ...payload, currencyId });

  const response = yield call(PricesService.savePrice, data, supplierId);
  if (!response?.data) {
    return;
  }

  yield put(pricesActions.hideAddPriceModal());
  yield put(
    pricesActions.getPrices({
      per_page: 10,
      page: 1,
      lab_id: labId,
      order: "asc",
      price_list_id: priceListId,
    })
  );
}

function* priceDelete({ payload }) {
  const { priceId, priceListId } = payload;

  const labId = yield select(selectLabId);

  const response = yield call(PricesService.deletePrice, priceId);
  if (!response?.data) {
    return;
  }

  yield put(
    pricesActions.getPrices({
      per_page: 10,
      page: 1,
      lab_id: labId,
      order: "asc",
      price_list_id: priceListId,
    })
  );
}

function* priceUpdate({ payload }) {
  const { id, values, priceListId } = payload;

  const supplierId = yield select(selectSupplierId);
  const currencyId = yield select(selectCurrencyId);
  const data = createPriceData({ ...values, priceListId, currencyId });

  const response = yield call(PricesService.updatePrice, id, supplierId, data);
  if (!response?.data) {
    return;
  }

  const { relationships, ...rest } = response.data.data;
  const result = getItemFields(relationships, response.data.included);
  yield put(pricesActions.hideAddPriceModal());

  const { prices } = yield select(selectPrices);
  const priceCopy = structuredClone(prices);
  const idx = priceCopy.findIndex((price) => price.id === id);
  priceCopy[idx] = { ...rest, ...result };

  yield put(pricesActions.pricesRefresh(priceCopy));
}

export default function* userSaga() {
  yield all([
    takeLatest(pricesActions.GET_PRICES, getPrices),
    takeLatest(pricesActions.PRICE_SAVE, priceSave),
    takeLatest(pricesActions.PRICE_DELETE, priceDelete),
    takeLatest(pricesActions.PRICE_UPDATE, priceUpdate),
  ]);
}
