import React, { useEffect } from "react";

const UserLayout = ({ children }) => {
  useEffect(() => {
    document.body.classList.add("background");
    return () => {
      document.body.classList.remove("background");
    };
  }, []);

  return (
    <>
      <div className='fixed-background' />
      <main>
        <div className='container'>{children}</div>
      </main>
    </>
  );
};

export default UserLayout;
