export const ORDER_DELIVERY_STATUS = {
  notYetAccepted: "to-be-accepted",
  accepted: "accepted",
  inProgress: "in-progress",
  delivered: "delivered,received",
  invoiced: "invoiced",
  closed: "closed",
};

export const ORDER_DELIVERY_STATUS_TITLE = {
  notYetAccepted: "Not yet accepted",
  accepted: "Accepted",
  inProgress: "In progress",
  delivered: "Sent",
  invoiced: "Invoiced",
  closed: "Closed",
};

export const ORDER_DELIVERY_STATUS_OPTIONS = [
  {
    value: ORDER_DELIVERY_STATUS.notYetAccepted,
    title: ORDER_DELIVERY_STATUS_TITLE.notYetAccepted,
  },
  {
    value: ORDER_DELIVERY_STATUS.accepted,
    title: ORDER_DELIVERY_STATUS_TITLE.accepted,
  },
  {
    value: ORDER_DELIVERY_STATUS.inProgress,
    title: ORDER_DELIVERY_STATUS_TITLE.inProgress,
  },
  {
    value: ORDER_DELIVERY_STATUS.delivered,
    title: ORDER_DELIVERY_STATUS_TITLE.delivered,
  },
  {
    value: ORDER_DELIVERY_STATUS.invoiced,
    title: ORDER_DELIVERY_STATUS_TITLE.invoiced,
  },
  {
    value: ORDER_DELIVERY_STATUS.closed,
    title: ORDER_DELIVERY_STATUS_TITLE.closed,
  },
];

export const ORDER_CONTROL_STATUS = {
  acceptOrder: "acceptOrder",
  closeOrder: "closeOrder",
  orderClosed: "orderClosed",
};

export const ORDER_CONTROL_STATUS_TITLE = {
  acceptOrder: "Accept order",
  closeOrder: "Close order",
  orderClosed: "Order closed",
};

export const ORDER_BUTTON_EVENT = {
  acceptOrder: "acceptOrder",
  closeOrder: "closeOrder",
  orderClosed: "orderClosed",
  orderAccepted: "orderAccepted",
};

export const ORDER_BUTTON_EVENT_TITLE = {
  acceptOrder: "Accept order",
  closeOrder: "Close order",
  orderClosed: "Order closed",
  orderAccepted: "Order accepted",
};

export const ORDER_STATUS = {
  closed: "closed",
  invoiced: "invoiced",
  inProgress: "inProgress",
  delivered: "delivered",
  accepted: "accepted",
  notYetAccepted: "notYetAccepted",
};

export const ORDER_STATUS_BADGE_TITLE = {
  closed: "C",
  invoiced: "I",
  inProgress: "IP",
  delivered: "S",
  accepted: "A",
  notYetAccepted: "N",
};

export const ORDER_STATUS_BADGE_TOOLTIP = {
  closed: "Closed",
  invoiced: "Invoiced",
  inProgress: "In Progress",
  delivered: "Sent",
  accepted: "Accepted",
  notYetAccepted: "Not yet accepted",
};

export const ORDER_STATUS_BADGE_CLASS = {
  closed: "grey-badge",
  invoiced: "green-badge",
  inProgress: "orange-badge",
  delivered: "blue-badge",
  accepted: "yellow-badge",
  notYetAccepted: "red-badge",
};

export const ORDER_BADGE_OPTIONS = {
  [ORDER_STATUS.closed]: {
    title: ORDER_STATUS_BADGE_TITLE.closed,
    tooltip: ORDER_STATUS_BADGE_TOOLTIP.closed,
    class: ORDER_STATUS_BADGE_CLASS.closed,
  },
  [ORDER_STATUS.invoiced]: {
    title: ORDER_STATUS_BADGE_TITLE.invoiced,
    tooltip: ORDER_STATUS_BADGE_TOOLTIP.invoiced,
    class: ORDER_STATUS_BADGE_CLASS.invoiced,
  },
  [ORDER_STATUS.inProgress]: {
    title: ORDER_STATUS_BADGE_TITLE.inProgress,
    tooltip: ORDER_STATUS_BADGE_TOOLTIP.inProgress,
    class: ORDER_STATUS_BADGE_CLASS.inProgress,
  },
  [ORDER_STATUS.delivered]: {
    title: ORDER_STATUS_BADGE_TITLE.delivered,
    tooltip: ORDER_STATUS_BADGE_TOOLTIP.delivered,
    class: ORDER_STATUS_BADGE_CLASS.delivered,
  },
  [ORDER_STATUS.accepted]: {
    title: ORDER_STATUS_BADGE_TITLE.accepted,
    tooltip: ORDER_STATUS_BADGE_TOOLTIP.accepted,
    class: ORDER_STATUS_BADGE_CLASS.accepted,
  },
  [ORDER_STATUS.notYetAccepted]: {
    title: ORDER_STATUS_BADGE_TITLE.notYetAccepted,
    tooltip: ORDER_STATUS_BADGE_TOOLTIP.notYetAccepted,
    class: ORDER_STATUS_BADGE_CLASS.notYetAccepted,
  },
};
