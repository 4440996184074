import {
  all,
  call,
  put,
  takeLatest,
  takeEvery,
  select,
} from "redux-saga/effects";
import { push } from "connected-react-router";
import toast from "react-hot-toast";
import base64js from "base64-js";

import { addDeliveryStatus } from "../../helpers/deliveries";
import { OrdersService } from "../../services/OrdersService";
import { OrderResponsesService } from "../../services/OrderResponsesService";
import { OrderLinesService } from "../../services/OrderLinesService";
import { PricesService } from "../../services/PricesService";
import { PriceListsService } from "../../services/PriceListsService";
import { DeliveriesService } from "../../services/DeliveriesService";
import { LabFilesService } from "../../services/LabFilesService";
import { dashboardActions as actions } from "./actions";
import {
  selectCurrentOrder,
  selectItems,
  selectItemsId,
  selectCheckedItemsId,
  selectPriceListId,
  selectFileLoadingIds,
} from "./selectors";
import { getItemFields } from "../../helpers/get-item-fields";
import { printFile, downloadFile } from "../../helpers/common";
import { addOrdersStatus } from "../../helpers/orders";
import { parseSearchPriceList } from "../../helpers/responce-parsers";
import {
  createItemData,
  createDeliveryData,
} from "../../helpers/request-creators";

function* getOrders({ payload }) {
  try {
    const orders = yield call(OrdersService.getOrders, payload);
    const { pagination } = orders.data.meta;
    if (orders.data) {
      const results = orders.data.data.map((item) => {
        const { relationships, ...rest } = item;
        const result = getItemFields(relationships, orders.data.included);
        return { ...result, ...rest };
      });

      const ordersWithStatus = addOrdersStatus(results);
      yield put(
        actions.getOrdersSuccess({ results: ordersWithStatus, pagination })
      );
    } else {
      yield put(actions.dashboardLoadingError());
    }
  } catch (error) {
    yield put(actions.dashboardLoadingError());
  }
}

function* getOrderById({ payload }) {
  try {
    const order = yield call(OrdersService.getOrderById, payload);
    if (order.data) {
      const { relationships, ...rest } = order.data.data;
      const result = getItemFields(relationships, order.data.included);
      const results = { ...result, ...rest };
      yield put(actions.getOrderByIdSuccess(results));
    } else {
      yield put(actions.dashboardLoadingError());
    }
  } catch (error) {
    yield put(actions.dashboardLoadingError());
  }
}

function* addAndSignOrderById({ payload }) {
  const { files = [], password, orderId, text } = payload;

  const data = {
    type: "lab_dental_lab_order_response",
    attributes: {
      text,
      ...(files.length ? {} : { signing_password: password }),
      dental_lab_order_id: orderId,
    },
  };

  const response = yield call(
    OrderResponsesService.addResponseToOrderById,
    data
  );
  if (!response?.data) {
    yield put(actions.addAndSignOrderByIdError());
    return;
  }

  if (!files.length) {
    yield put(actions.addAndSignOrderByIdSuccess());
    payload.resetResponse();
    return;
  }

  const responseId = response.data.data.id;

  const fileResponses = yield all(
    files.map((file) => {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("name", file.name);
      formData.append("lab_dental_lab_order_response_id", responseId);
      return call(LabFilesService.fileUpload, formData);
    })
  );

  const isAllSuccess = fileResponses.every((item) => item.data?.data?.id);
  if (!isAllSuccess) {
    yield put(actions.addAndSignOrderByIdError());
    return;
  }

  const responseSign = yield call(
    OrderResponsesService.signResponse,
    responseId,
    password
  );

  if (!responseSign?.data) {
    yield put(actions.addAndSignOrderByIdError());
    return;
  }

  payload.resetResponse();
  yield put(actions.addAndSignOrderByIdSuccess());
}

function* loadResponseFile({ payload }) {
  const { parentId, fileId, name, parentKey } = payload;

  const fileLoadingIds = yield select(selectFileLoadingIds);
  if (fileLoadingIds.includes(fileId)) {
    return;
  }

  yield put(actions.fileLoadingIDsRefresh([...fileLoadingIds, fileId]));
  const accessToken = localStorage.getItem("user_token_lab_frontend");

  const data = {
    data: {},
    headers: {
      accept: "application/x.journal.v1+json",
      authorization: `Bearer ${accessToken}`,
    },
  };

  const response = yield call(LabFilesService.fileDownload, {
    fileId,
    data,
    parentKey,
    parentId,
  });

  const latestLoadingIds = yield select(selectFileLoadingIds);
  const fileIdRes = latestLoadingIds?.filter((id) => id !== fileId);

  if (!response?.body) {
    yield put(actions.fileLoadingIDsRefresh(fileIdRes));
    return;
  }

  try {
    const responseText = yield call([response, "text"]);
    const data = base64js.toByteArray(responseText);
    yield call(downloadFile, name, data, response.headers["content-type"]);
  } catch (error) {
    toast.error(`Error while loading file ${name}`);
  }

  yield put(actions.fileLoadingIDsRefresh(fileIdRes));
}

function* closeOrderById({ payload }) {
  const { orderId } = payload;
  const response = yield call(OrdersService.closeOrderById, orderId);

  if (!response?.data) {
    yield put(actions.closeOrderByIdError());
  }
  yield put(actions.closeOrderByIdSuccess());
  yield put(push("/app/dashboard/orders"));
}

function* acceptOrderById({ payload }) {
  const { orderId } = payload;
  const response = yield call(OrdersService.acceptOrderById, orderId);

  if (!response?.data) {
    yield put(actions.acceptOrderByIdError());
  }
  yield put(actions.acceptOrderByIdSuccess());
  yield put(push("/app/dashboard/orders"));
}

function* itemsReload({ payload }) {
  try {
    const response = yield call(OrderLinesService.getItem, payload.id);
    if (!response?.data?.data) {
      return;
    }

    const items = response.data.data.map((item) => {
      const { relationships, ...rest } = item;
      const result = getItemFields(relationships, response.data.included);
      return { ...result, ...rest };
    });

    yield put(actions.itemsRefresh(items));
  } catch (error) {
    yield put(actions.dashboardLoadingError());
  }
}

function* deliveriesReload({ payload }) {
  const response = yield call(DeliveriesService.getDelivery, payload.id);
  if (!response?.data?.data) {
    yield put(actions.dashboardLoadingError());

    return;
  }

  const deliveries = response.data.data.map((item) => {
    const { relationships, ...rest } = item;
    const result = getItemFields(relationships, response.data.included);
    return { ...result, ...rest };
  });
  const deliveriesWithStatus = addDeliveryStatus(deliveries);

  yield put(actions.deliveriesRefresh(deliveriesWithStatus));
}

function* deliveryPdfDownload({ payload }) {
  const { id } = payload;
  const response = yield call(DeliveriesService.getPdf, id);
  if (!response.data) {
    return;
  }

  const name = `dental-lab-delivery-${id}`;
  const convertedPdfData = base64js.toByteArray(response.data);
  downloadFile(name, convertedPdfData, response.headers["content-type"]);
}

function* deliveryPdfPrint({ payload }) {
  const { id } = payload;
  const response = yield call(DeliveriesService.getPdf, id);
  if (!response.data) {
    return;
  }

  const convertedPdfData = base64js.toByteArray(response.data);
  printFile(convertedPdfData);
}

function* createDelivery() {
  const { id } = yield select(selectCurrentOrder);
  const checkedItemsId = yield select(selectCheckedItemsId);

  const orderLines = checkedItemsId.map((id) => id);
  const data = createDeliveryData({ orderId: id, orderLines });

  const response = yield call(DeliveriesService.saveDelivery, data);
  if (!response?.data?.data) {
    yield put(actions.dashboardLoadingError());
  }

  yield put(actions.deliveriesReload(id));
  yield put(actions.itemsReload(id));
  yield put(actions.checkedItemsIdRefresh([]));
  yield put(actions.dashboardLoadingSuccess());
}

function* itemCheckChange(action) {
  const { id, checked } = action.payload;

  const checkedItemsId = yield select(selectCheckedItemsId);
  const resItemsId = structuredClone(checkedItemsId);

  if (checked && !resItemsId.includes(id)) {
    resItemsId.push(id);
  }
  if (!checked && resItemsId.includes(id)) {
    resItemsId.splice(resItemsId.indexOf(id), 1);
  }

  yield put(actions.checkedItemsIdRefresh(resItemsId));
}

function* allItemsCheckChange(action) {
  const checked = action.payload;

  if (checked) {
    const itemsId = yield select(selectItemsId);
    yield put(actions.checkedItemsIdRefresh(itemsId));
    return;
  }
  yield put(actions.checkedItemsIdRefresh([]));
}

function* itemSave({ payload }) {
  const { id } = yield select(selectCurrentOrder);

  const data = createItemData(payload);
  const response = yield call(OrderLinesService.saveItem, data, id);
  if (!response?.data?.data) {
    yield put(actions.dashboardLoadingError());
  }

  const { relationships, ...rest } = response.data.data;
  const { vat_code } =
    getItemFields(relationships, response.data.included) || {};

  const items = yield select(selectItems);

  yield put(actions.itemsRefresh([...items, { vat_code, ...rest }]));
  yield put(actions.hideAddItemModal());
  yield put(actions.dashboardLoadingSuccess());
}

function* itemUpdate({ payload }) {
  const { id, values } = payload;
  const { id: orderId } = yield select(selectCurrentOrder);

  const data = createItemData(values);
  const response = yield call(OrderLinesService.updateItem, id, data, orderId);
  if (!response?.data?.data) {
    yield put(actions.dashboardLoadingError());
  }

  const { relationships, ...rest } = response.data.data;
  const { vat_code } =
    getItemFields(relationships, response.data.included) || {};

  const items = yield select(selectItems);
  const itemsCopy = structuredClone(items);
  const idx = itemsCopy.findIndex((price) => price.id === id);
  itemsCopy[idx] = { ...rest, vat_code };

  yield put(actions.itemsRefresh(itemsCopy));
  yield put(actions.hideAddItemModal());
}

function* itemDelete({ payload }) {
  const { itemId } = payload;

  const response = yield call(OrderLinesService.deleteItem, itemId);
  if (!response) {
    yield put(actions.dashboardLoadingError());
    return;
  }

  const itemList = yield select(selectItems);
  const resItemList = itemList.filter((item) => item.id !== itemId);
  yield put(actions.itemsRefresh(resItemList));
  yield put(actions.dashboardLoadingSuccess());
}

function* getSearchPrices({ payload }) {
  try {
    let priseListId = yield select(selectPriceListId);
    if (!priseListId) {
      const priceListResponse = yield call(PriceListsService.getPriceLists, {
        per_page: 1,
        page: 1,
        order: "desc",
      });
      if (priceListResponse?.data?.data) {
        priseListId = priceListResponse.data.data[0].id;
        yield put(actions.priceListIdRefresh(priseListId));
      }
    }

    const response = yield call(PricesService.getPrices, {
      price_list_id: priseListId,
      ...payload,
    });
    if (response.data) {
      const results = response.data.data.map((item) => {
        const { relationships, ...rest } = item;
        const result = getItemFields(relationships, response.data.included);
        return { ...result, ...rest };
      });

      const parsedResult = parseSearchPriceList(results);
      yield put(actions.getSearchPricesSuccess(parsedResult));
    }
  } catch (error) {
    yield put(actions.getSearchPricesError());
  }
}

export default function* dashBoardSaga() {
  yield all([
    takeLatest(actions.DASHBOARD_GET_ORDERS, getOrders),
    takeLatest(actions.DASHBOARD_GET_ORDER_BY_ID, getOrderById),
    takeLatest(actions.DASHBOARD_ADD_AND_SIGN_ORDER_BY_ID, addAndSignOrderById),
    takeEvery(actions.DASHBOARD_LOAD_RESPONSE_FILE, loadResponseFile),
    takeLatest(actions.DASHBOARD_CLOSE_ORDER_BY_ID, closeOrderById),
    takeLatest(actions.DASHBOARD_ACCEPT_ORDER_BY_ID, acceptOrderById),
    takeLatest(actions.DASHBOARD_ITEMS_RELOAD, itemsReload),
    takeLatest(actions.DASHBOARD_DELIVERIES_RELOAD, deliveriesReload),
    takeLatest(actions.DASHBOARD_DELIVERY_PDF_DOWNLOAD, deliveryPdfDownload),
    takeLatest(actions.DASHBOARD_DELIVERY_PRINT, deliveryPdfPrint),
    takeLatest(actions.DASHBOARD_CREATE_DELIVERY, createDelivery),
    takeLatest(actions.DASHBOARD_ITEM_CHECK_CHANGE, itemCheckChange),
    takeLatest(actions.DASHBOARD_ALL_ITEM_CHECK_CHANGE, allItemsCheckChange),
    takeLatest(actions.DASHBOARD_ALL_ITEM_CHECK_CHANGE, allItemsCheckChange),
    takeLatest(actions.DASHBOARD_ITEM_SAVE, itemSave),
    takeLatest(actions.DASHBOARD_ITEM_UPDATE, itemUpdate),
    takeLatest(actions.DASHBOARD_ITEM_DELETE, itemDelete),
    takeLatest(actions.DASHBOARD_GET_SEARCH_PRICES, getSearchPrices),
  ]);
}
