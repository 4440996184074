import { BaseService } from "../BaseService";
import { API_ROUTES } from "../../constants/routes";

class PriceListsService extends BaseService {
  constructor(url) {
    super(url);
    this.getPriceLists = this.getPriceLists.bind(this);
    this.savePriceList = this.savePriceList.bind(this);
    this.deletePriceList = this.deletePriceList.bind(this);
    this.updatePriceList = this.updatePriceList.bind(this);
  }

  async getPriceLists(params) {
    try {
      const response = await this.agent.get("", {
        params: {
          ...params,
          sort_by: "id",
          deleted: 0,
        },
      });
      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      return null;
    }
  }

  async savePriceList(data, supplierId) {
    try {
      const response = await this.agent.post(
        "",
        { data },
        {
          params: {
            supplier_id: supplierId,
          },
        }
      );

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      return null;
    }
  }

  async deletePriceList(priceId) {
    try {
      const response = await this.agent.patch(`/${priceId}`, null, {
        params: {
          deleted: 1,
        },
      });

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      return null;
    }
  }

  async updatePriceList(priceId, supplierId, data) {
    try {
      const response = await this.agent.patch(
        `/${priceId}`,
        { data },
        {
          params: {
            supplier_id: supplierId,
          },
        }
      );

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      return null;
    }
  }
}

const ServiceInstance = new PriceListsService(API_ROUTES.labPriceLists);

export default ServiceInstance;
export { ServiceInstance as PriceListsService };
