const TYPES = {
  GET_PRICE_LISTS: "GET_PRICE_LISTS",
  GET_PRICE_LISTS_SUCCESS: "GET_PRICE_LISTS_SUCCESS",
  GET_PRICE_LISTS_ERROR: "GET_PRICE_LISTS_ERROR",
  PRICE_LISTS_UI_REFRESH: "PRICE_LISTS_UI_REFRESH",

  ADD_PRICE_LIST_MODAL_SHOW: "ADD_PRICE_LIST_MODAL_SHOW",
  ADD_PRICE_LIST_MODAL_HIDE: "ADD_PRICE_LIST_MODAL_HIDE",
  PRICE_LISTS_REFRESH: "PRICE_LISTS_REFRESH",
  PRICE_LIST_SAVE: "PRICE_LIST_SAVE",
  PRICE_LIST_DELETE: "PRICE_LIST_DELETE",
  PRICE_LIST_UPDATE: "PRICE_LIST_UPDATE",
  CURRENT_PRICE_LIST_REFRESH: "CURRENT_PRICE_LIST_REFRESH",
};

export const priceListsActions = {
  ...TYPES,
  getPriceLists: (payload) => ({
    type: TYPES.GET_PRICE_LISTS,
    payload,
  }),
  getPriceListsSuccess: (payload) => ({
    type: TYPES.GET_PRICE_LISTS_SUCCESS,
    payload,
  }),
  getPriceListsError: () => ({
    type: TYPES.GET_PRICE_LISTS_ERROR,
  }),
  pricesListUIrefresh: (payload) => ({
    type: TYPES.PRICE_LISTS_UI_REFRESH,
    payload,
  }),
  showAddPriceListModal: () => ({
    type: TYPES.ADD_PRICE_LIST_MODAL_SHOW,
  }),
  hideAddPriceListModal: () => ({
    type: TYPES.ADD_PRICE_LIST_MODAL_HIDE,
  }),
  priceListsRefresh: (priceList) => ({
    type: TYPES.PRICE_LISTS_REFRESH,
    payload: { priceList },
  }),
  priceListSave: (payload) => ({
    type: TYPES.PRICE_LIST_SAVE,
    payload,
  }),
  priceListDelete: (id) => ({
    type: TYPES.PRICE_LIST_DELETE,
    payload: { id },
  }),
  priceListUpdate: (id, priceList) => ({
    type: TYPES.PRICE_LIST_UPDATE,
    payload: { id, priceList },
  }),
  currentPriceListRefresh: (priceList) => ({
    type: TYPES.CURRENT_PRICE_LIST_REFRESH,
    payload: { priceList },
  }),
};
