import React from "react";
import { Toaster } from "react-hot-toast";

const ToasterContainer = () => {
  return (
    <Toaster
      position='top-right'
      reverseOrder={false}
      toastOptions={{
        icon: false,
        duration: 3000,
        style: {
          padding: "1rem",
          width: "320px",
          justifyContent: "flex-start",
          fontSize: "1.1rem",
        },
      }}
    />
  );
};

export default ToasterContainer;
