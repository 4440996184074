import React from "react";

const PrintIcon = () => (
  <svg
    xmlns='https://www.w3.org/2000/svg'
    viewBox='0 0 525 525'
    style={{ width: "20px", height: "20px" }}
  >
    <path d='M433.5 153h-357C33.15 153 0 186.15 0 229.5v153h102v102h306v-102h102v-153c0-43.35-33.15-76.5-76.5-76.5zM357 433.5H153V306h204v127.5zM433.5 255c-15.3 0-25.5-10.2-25.5-25.5s10.2-25.5 25.5-25.5 25.5 10.2 25.5 25.5-10.2 25.5-25.5 25.5zM408 25.5H102v102h306v-102z' />
  </svg>
);

export default PrintIcon;
