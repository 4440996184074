import { BaseService } from "../BaseService";
import { API_ROUTES } from "../../constants/routes";

import config from "../../config";

class LabFilesService extends BaseService {
  constructor(url) {
    super(url, config.fileapi);
    this.fileUpload = this.fileUpload.bind(this);
    this.fileDownload = this.fileDownload.bind(this);
  }

  async fileUpload(formData) {
    try {
      const response = await this.agent.post("/upload", formData);

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      return null;
    }
  }

  async fileDownload({ fileId, data, parentKey, parentId }) {
    try {
      const response = await fetch(
        `${config.fileapi}/lab-files/${fileId}/download?${parentKey}=${parentId}`,
        data
      );

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      return null;
    }
  }
}

const ServiceInstance = new LabFilesService(API_ROUTES.labFiles);

export default ServiceInstance;
export { ServiceInstance as LabFilesService };
