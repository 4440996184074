export const STATUS = {
  draft: "draft",
  sent: "sent",
  received: "received",
  partlyInvoiced: "partlyInvoiced",
  invoiced: "invoiced",
};

export const STATUS_TOOLTIP = {
  draft: "Draft",
  sent: "Sent",
  received: "Received",
  partlyInvoiced: " Partly invoiced",
  invoiced: "Invoiced",
};

export const STATUS_TITLE = {
  draft: "D",
  sent: "S",
  received: "R",
  partlyInvoiced: " PI",
  invoiced: "I",
};

export const STATUS_BADGE_CLASS = {
  draft: "yellow-badge",
  sent: "blue-badge",
  received: "red-badge",
  partlyInvoiced: "purple-budge",
  invoiced: "green-badge",
};

export const STATUS_OPTIONS = {
  [STATUS.draft]: {
    title: STATUS_TITLE.draft,
    tooltip: STATUS_TOOLTIP.draft,
    class: STATUS_BADGE_CLASS.draft,
  },
  [STATUS.sent]: {
    title: STATUS_TITLE.sent,
    tooltip: STATUS_TOOLTIP.sent,
    class: STATUS_BADGE_CLASS.sent,
  },
  [STATUS.received]: {
    title: STATUS_TITLE.received,
    tooltip: STATUS_TOOLTIP.received,
    class: STATUS_BADGE_CLASS.received,
  },
  [STATUS.partlyInvoiced]: {
    title: STATUS_TITLE.partlyInvoiced,
    tooltip: STATUS_TOOLTIP.partlyInvoiced,
    class: STATUS_BADGE_CLASS.partlyInvoiced,
  },
  [STATUS.invoiced]: {
    title: STATUS_TITLE.invoiced,
    tooltip: STATUS_TOOLTIP.invoiced,
    class: STATUS_BADGE_CLASS.invoiced,
  },
};
